
import BsDialog from '@/components/BsDialog/BsDialog.vue'
import {PubSubFactory, PubSubs} from '@/mid-layer/Factories/PubSubFactory'
import {CoachListUpdated} from '@/types/Topic'
import type {Coach} from '@/types/Coach'
import BsMultiSelect from '@/components/BsSelect/BsMultiSelect.vue'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import BsCheckbox from '@/components/BsCheckbox/BsCheckbox.vue'
import TextEditor from '@/components/Editor/TextEditor.vue'
import type {MultiSelectOptionsModel} from '@/components/BsSelect/BsMultiSelect.interfaces'
import constants from '@/utils/Constants.json'
import useVuelidate, { ValidationArgs } from '@vuelidate/core'
import {required, requiredIf} from '@vuelidate/validators'
import {
  defineComponent,
  watch,
  ref,
  computed,
  PropType,
  onMounted
} from 'vue'
import locales from '@/components/AddCoach/AddCoach.locales.en.json'
import { injectAddCoachStore } from './AddCoach.store'
import DeleteIcon from '@/components/icons/DeleteIcon.vue'
import UploadArrow from '@/components/icons/UploadArrow.vue'
import ImageUploadModal from '../Shared/ImageUploadModal.vue'
import DeleteConfirmationModal from '../Shared/DeleteConfirmationModal.vue'
import { useToast } from '../ToastQueue/ToastQueue.utils'

export default defineComponent({
  components: {
    BsDialog,
    BsMultiSelect,
    BsTextField,
    BsCheckbox,
    TextEditor,
    DeleteIcon,
    UploadArrow,
    ImageUploadModal,
    DeleteConfirmationModal
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    editDetails: {
      type: Object as PropType<Coach>,
      default: () => null
    },
    profilePicture: {
      type: Blob,
      default: () => null
    }
  },
  emits: ['Update:isVisible'],
  setup(props, {emit}) {
    const { showErrorToast } = useToast()

    const state = ref<Coach>(getInitialState())
    const isSaving = ref<boolean>(false)
    const editMode = ref<boolean>(!!props.editDetails)
    const visibleDeleteDialog = ref<boolean>(false)

    const profilePictureSource = ref('')
    const profilePictureFile = ref<File>()
    const selectedImage = ref<File>()
    const showImageUploadModal = ref(false)
    const showDeleteImageConfirmationModal = ref(false)

    const products = constants.Products as MultiSelectOptionsModel[]
    const jurisdictions = constants.Jurisdictions as MultiSelectOptionsModel[]

    const store = injectAddCoachStore()

    const pubSub = PubSubFactory.GetPubSub(PubSubs.PubSubJS)

    const rules: ValidationArgs = {
      jurisdictions: { required: requiredIf(() => !isLsat.value ) },
      products: {required},
      name: {required}
    }

    const v$ = useVuelidate(rules, state)

    function getInitialState() {
      return {
        id: '',
        name: '',
        aboutMe: '',
        jurisdictions: [],
        products: [],
        isActive: true,
        profilePictureFileName: ''
      }
    }

    watch(
      () => props.isVisible,
      () => {
        editMode.value = !!props.editDetails
        if (editMode.value) {
          state.value = props.editDetails
        } else {
          state.value = getInitialState()
        }
      }
    )
    
    watch(() => props.profilePicture, (newValue) => {
      if (newValue) {
        try {
          profilePictureFile.value = new File([newValue], props.editDetails.profilePictureFileName)
          profilePictureSource.value = window.URL.createObjectURL(newValue)
        } catch {
          showErrorToast({ message: 'Cannot display profile picture' })
        }
      }
    })

    function closeModal() {
      state.value = getInitialState()
      v$.value.$reset()
      deleteImage()
      emit('Update:isVisible', false)
    }

    async function onSubmit() {
      try {
        const isValid = await v$.value.$validate()
        if (isValid) {
          isSaving.value = true

          if (editMode.value) {
           await store.actions.editCoach(state.value.id, state.value, profilePictureFile.value)
          } else {
           await store.actions.addCoach(state.value, profilePictureFile.value)
          }
          closeModal()
          pubSub.Publish(CoachListUpdated)
        }
      } finally {
        isSaving.value = false
      }
    }

    const computeVisibleModal = computed({
      get: () => props.isVisible,
      set: (val) => emit('Update:isVisible', val)
    })

    function onDelete() {
      visibleDeleteDialog.value = true
    }

    async function onSubmitDelete() {
        visibleDeleteDialog.value = false
        const {id} = state.value
        closeModal()
        await store.actions.deleteCoach(id)
        deleteImage()
    }

    function closeDeleteDialog() {
      visibleDeleteDialog.value = false
    }

    function handleEditProfilePicture() {
      const fileInput = document.getElementById('profile-picture-input')
      if (fileInput) {
        fileInput.click()
      }
    }

    function handleDeleteProfilePicture() {
      showDeleteImageConfirmationModal.value = true
    }

    function deleteImage() {
      selectedImage.value = undefined
      profilePictureFile.value = undefined
      profilePictureSource.value = ''
      showDeleteImageConfirmationModal.value = false
    }

    function handleProfilePictureSelected(e: Event) {
      const files = (e.target as HTMLInputElement).files
      if (!files || !files.length) {
        return
      }
      selectedImage.value = files[0]
      showImageUploadModal.value = true
    }

    function closeImageUploadModel() {
      selectedImage.value = undefined
      showImageUploadModal.value = false
    }

    async function onProfilePictureSubmitted(blob: Blob) {
      try {
        profilePictureFile.value = new File([blob], selectedImage.value?.name ?? '')
        profilePictureSource.value = window.URL.createObjectURL(blob)
      }
      catch {
        showErrorToast({ message: 'Cannot change current profile picture' })
      }
    }

    const isLsat = computed(() => {
      return state.value.products.length > 0 &&
        state.value.products.every(productValue => {
          return constants.Products.some(product => 
            product.value === productValue && product.grouping === constants.LSATGrouping)
        })
    })
    
    watch(isLsat, () => {
      if (isLsat.value) {
        state.value.jurisdictions = []
      }
    })

    return {
      state,
      products,
      jurisdictions,
      v$,
      onSubmit,
      closeModal,
      locales,
      isSaving,
      computeVisibleModal,
      editMode,
      onDelete,
      onSubmitDelete,
      closeDeleteDialog,
      visibleDeleteDialog,
      profilePictureSource,
      profilePictureFile,
      selectedImage,
      showImageUploadModal,
      showDeleteImageConfirmationModal,
      isLsat,
      handleEditProfilePicture,
      handleDeleteProfilePicture,
      handleProfilePictureSelected,
      closeImageUploadModel,
      deleteImage,
      onProfilePictureSubmitted
    }
  }
})
