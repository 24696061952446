
import { CourseTag } from '@/views/Events/Events.interface'
import { defineComponent, PropType, ref } from 'vue'
import EditTag from './EditTag.vue'

export default defineComponent({
  components: {
    EditTag
  },
  props: {
    tagList: {
      type: Object as PropType<CourseTag[]>,
      default: () => null
    }
  },
  setup(props) {
    const isEditTagModalOpen = ref<boolean>(false)
    const selectedTag = ref<CourseTag | null>()
    const courseTagList = ref<CourseTag[]>(props.tagList)

    async function openEditModal(rowValue: CourseTag) {
      selectedTag.value = rowValue
      isEditTagModalOpen.value = true
    }

    async function addTag(newTag: CourseTag) {
      if(!newTag) {
        return
      }

       courseTagList.value.push(newTag)
    }

    async function updateTag(updatedTag: CourseTag) {
       if(!updatedTag || !selectedTag.value) {
        return
      }

      const itemIndex = courseTagList.value.indexOf(selectedTag.value)
      courseTagList.value[itemIndex] = updatedTag
    }

    async function removeTag() {
      if(!selectedTag.value) {
        return
      }

      courseTagList.value.splice(courseTagList.value.indexOf(selectedTag.value), 1)
    }

    return { 
      openEditModal,
      isEditTagModalOpen,
      selectedTag,
      addTag,
      updateTag,
      removeTag,
      courseTagList
    }
  }
})
