import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2dea11ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { ref: "dropdownRef" }
const _hoisted_2 = {
  key: 0,
  class: "header-dropdown-segment"
}
const _hoisted_3 = { class: "username-segment" }
const _hoisted_4 = { class: "name-content" }
const _hoisted_5 = { class: "name" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserNameIcon = _resolveComponent("UserNameIcon")!
  const _component_BsSpinner = _resolveComponent("BsSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UserNameIcon, {
      nameDetails: _ctx.nameDetails,
      isDropdownOpen: _ctx.isOpen,
      onToggleDropdown: _ctx.handleDropdownChange
    }, null, 8, ["nameDetails", "isDropdownOpen", "onToggleDropdown"]),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createVNode(_component_UserNameIcon, { "name-details": _ctx.nameDetails }, null, 8, ["name-details"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.nameDetails.fullName), 1)
            ])
          ]),
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropdownOptions, (option, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                role: "button",
                tabindex: "0",
                onClick: ($event: any) => (_ctx.handleOptionClick($event, option))
              }, [
                (option.loading)
                  ? (_openBlock(), _createBlock(_component_BsSpinner, {
                      key: 0,
                      class: "me-3"
                    }))
                  : (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: _normalizeClass(["bi fs-4 me-3", 'bi-' + option.icon])
                    }, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(option.icon)))
                    ], 2)),
                _createElementVNode("span", null, _toDisplayString(option.label), 1)
              ], 8, _hoisted_6))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 512))
}