
import { computed, defineComponent, ref, watch } from 'vue'
import DropdownOptions from '@/components/Header/HeaderDropdown.vue'
import { BarbriProduct, type UserDetailsModel } from '@/components/Header/Header.interface'
import { dropdownOptions } from '@/components/Header/Header.util'
import { useUserStore } from '@/stores/UserStore'

const HeaderNavbar = defineComponent({
  name: 'HeaderNavbar',
  components: { DropdownOptions },
  emits: ['product-changed'],
  setup(props, {emit}) {
    const state = ref({
      fullName: '',
      nameKey: '',
      isAuthenticated: false
    })

    useUserStore().$subscribe((mutation, stateChanges) => {
      state.value.fullName = stateChanges.name
      state.value.nameKey = stateChanges.name
        .split(' ')
        .map((word) => word.charAt(0))
        .join('')

      state.value.isAuthenticated = stateChanges.isAuthenticated
    })

    const getUserName = computed<UserDetailsModel>(() => {
      return state.value
    })

    const showDropDown = ref(false)
    const selectedProduct = ref<BarbriProduct>(BarbriProduct.BarReview)

    watch(selectedProduct, () => {
      emit('product-changed', selectedProduct.value)
    })

    const otherProducts = computed(() => {
      return Object.values(BarbriProduct).filter(p => p != selectedProduct.value)
    })

    function switchProduct(product: BarbriProduct) {
      selectedProduct.value = product
      showDropDown.value = false
    }

    return {
      getUserName,
      dropdownOptions,
      state,
      showDropDown,
      selectedProduct,
      otherProducts,
      switchProduct
    }
  }
})

export default HeaderNavbar
