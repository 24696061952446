
import { SortItem, TableRowItem } from './TableView.interfaces'
import {
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  toRefs,
  watch
} from 'vue'
import ArrowUp from '@/components/icons/ArrowUp.vue'
import ArrowDown from '@/components/icons/ArrowDown.vue'
import ExpandArrows from '@/components/icons/ExpandArrows.vue'
import TableLoader from './TableLoader.vue'
import locales from './TableView.locales.en.json'

export default defineComponent({
  components: {
    TableLoader,
    ArrowUp,
    ArrowDown,
    ExpandArrows
  },
  props: {
    columns: {
      type: Array,
      required: true
    },
    data: {
      type: Array as PropType<TableRowItem[]>,
      required: true
    },
    initialSort: {
      type: Object as PropType<SortItem>,
      default: () => undefined
    },
    isLoading: {
      type: Boolean
    },
    selectedItems: {
      type: Array as PropType<number[]>,
      default: () => []
    },
    ariaLabel: {
      type: String,
      default: () => undefined
    },
    areRowsSelectable: {
      type: Boolean,
      default: true
    }
  },
  emits: ['row-clicked'],
  setup(props,{ emit }) {
    const sortedElement = reactive<SortItem>({
      order: '',
      sortKey: '',
      sortFunction: undefined
    })

    const currentData = ref<TableRowItem[]>([])

    const { isLoading, data } = toRefs(props)

    onMounted(() => {
      currentData.value = data.value
    })

    watch(data, () => {
      currentData.value = props.data
      handleSort(currentData.value, sortedElement)
    })

    watch(isLoading, (loadingState) => {
      if (!loadingState) {
        if (props.initialSort) {
          sortedElement.order = props.initialSort.order
          sortedElement.sortKey = props.initialSort.sortKey
          handleSort(props.data, props.initialSort)
        } else {
          currentData.value = props.data
        }
      }
    })

    function handleSort(arr: TableRowItem[], value: SortItem) {
      sortedElement.sortKey = value.sortKey
      sortedElement.order = value.order
      const key = sortedElement.sortKey

      let compareFunc: (a: TableRowItem, b: TableRowItem) => number = (a, b) => {
        if (a[key] < b[key]) return -1
        if (a[key] > b[key]) return 1
        return 0
      }

      if (value.sortFunction !== undefined) {
        compareFunc = value.sortFunction
      }

      let result = [...arr].sort(compareFunc)
      if (!isAscending(sortedElement.order)) {
        result = result.reverse()
      }
      currentData.value = result
    }

    const isAscending = (order: string) => {
      return order === 'asc'
    }

    return {
      sortedElement,
      handleSort,
      isAscending,
      currentData,
      locales
    }
  }
})
