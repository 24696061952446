
import { defineComponent, provide, computed, watch } from 'vue'
import {
  IBsTabContainerStoreSymbol,
  IBsTabContainerStore
} from '@/components/BsTab/BsTab.interfaces'
import { createBsTabStore } from '@/components/BsTab/BsTab.store'
import { createAutoIncrementIdProp } from '@/utils/VueTools'

export const BsTabContainer = defineComponent({
  name: 'BsTabContainer',
  props: {
    id: createAutoIncrementIdProp('BsTabContainer'),
    selectedTab: {
      type: String
    }
  },
  setup(props) {
    const store = createBsTabStore()
    const selectedTabId = computed(() => store.state.selectedTabId)
    provide<IBsTabContainerStore>(IBsTabContainerStoreSymbol, store)

    watch(() => props.selectedTab, () => {
      if (props.selectedTab) {
        store.actions.selectTab(props.selectedTab)
      }
    })

    return {
      selectedTabId
    }
  }
})

export default BsTabContainer
export type BsTabContainerInstance = InstanceType<typeof BsTabContainer>;
