
import { defineComponent, computed, PropType, ref, watch } from 'vue'
import BsDialog from '@/components/BsDialog/BsDialog.vue'
import { CourseTag, ProductSettings } from '@/views/Events/Events.interface'
import { PubSubFactory, PubSubs } from '@/mid-layer/Factories/PubSubFactory'
import { injectEventsStore } from '@/views/Events/Events.store'
import { CourseSettingsUpdated } from '@/types/Topic'
import { CourseSettingsTab, isOutOfRange } from './EditCourseSettings.interface'
import MaxAttendees from './MaxAttendees.vue'
import CourseTags from './CourseTags.vue'

export default defineComponent({
  components: {
    BsDialog,
    MaxAttendees,
    CourseTags
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    productsSettings: {
      type: Object as PropType<ProductSettings[]>,
      default: () => null
    },
    courseTags: {
      type: Object as PropType<CourseTag[]>,
      default: () => null
    }
  },
  emits: ['update:isVisible'],
  setup(props, {emit}) {
    const isSaving = ref<boolean>(false)
    const store = injectEventsStore()
    const productDictionary = ref<{ [grouping: string]: ProductSettings[] }>({})
    const tagList = ref<CourseTag[]>()
    const pubSub = PubSubFactory.GetPubSub(PubSubs.PubSubJS)
    const activeTab = ref<CourseSettingsTab>(CourseSettingsTab.MaximumAttendees)

    function closeModal() {
      emit('update:isVisible', false)
    }

    const computeVisibleModal = computed({
      get: () => props.isVisible,
      set: (val) => emit('update:isVisible', val)
    })
    
    watch(() => props.isVisible, () => {
      if (props.isVisible == true) {
        formatProductSettings()
        tagList.value = JSON.parse(JSON.stringify(props?.courseTags ?? {})) as CourseTag[]
      }
    })

    function formatProductSettings() {
      let settings = JSON.parse(JSON.stringify(props?.productsSettings ?? {})) as ProductSettings[]
      productDictionary.value = {}
      
      settings.forEach(s => {
        if(productDictionary.value[s.grouping])
        {
          productDictionary.value[s.grouping].push(s)
        }
        else
        {
          productDictionary.value[s.grouping] = [s]
        }
      })
    }

    async function onSubmit() {
      try {
        isSaving.value = true

        switch(activeTab.value) {
          case CourseSettingsTab.MaximumAttendees:
            await submitMaxAttendees()
            break
          case CourseSettingsTab.CourseTags:
            await submitCourseTags()
            break
          default:
            break
        }

        closeModal()
        pubSub.Publish(CourseSettingsUpdated)
      } finally {
        isSaving.value = false
      }
    }

    const invalidEntry = computed(() => {
      switch(activeTab.value) {
        case CourseSettingsTab.MaximumAttendees:
           return Object.values(productDictionary.value).some(grouping =>
            grouping.some(setting => isOutOfRange(setting.maxAttendees)
          ))
        case CourseSettingsTab.CourseTags:
        default:
          return false
      }
    })

    async function SetActiveTab(tab: CourseSettingsTab) {
      activeTab.value = tab
    }

    async function submitMaxAttendees() {
      let updateArray: ProductSettings[] = []

      Object.values(productDictionary.value).forEach(settings => {
        updateArray = updateArray.concat(settings)
      })

      await store.actions.updateProducts(updateArray)
    }

    async function submitCourseTags() { 
      if(tagList.value)
      {
         await store.actions.updateTags(tagList.value)
      }
    }

    return {
      computeVisibleModal,
      closeModal,
      isSaving,
      onSubmit,
      productDictionary,
      invalidEntry,
      isOutOfRange,
      SetActiveTab,
      CourseSettingsTab,
      activeTab,
      submitMaxAttendees,
      submitCourseTags,
      formatProductSettings,
      tagList
    }
  }
})
