import {OktaAuth, type IDToken} from '@okta/okta-auth-js'
import {Configuration} from '@/config/config'
import APIBroker from '@/mid-layer/broker/api-broker'

const scopes = ['openid', 'profile', 'email', 'groups', 'read', 'write', 'offline_access']

async function createSession(redirectUrl: string): Promise<string> {
    try {
        return await APIBroker.Instance.Post<{ redirectUrl: string },
            string>(
            'anon/sessions',
            {
                redirectUrl: redirectUrl
            },
            Configuration.SSU_ENDPOINT
        )
    } catch (e) {
        return Promise.reject(`Error:${e}`)
    }
}

let oktaAuth: OktaAuth = {} as OktaAuth

function InitOktaAuth(): void {
    oktaAuth = new OktaAuth({
        issuer: Configuration.AUTH_ENDPOINT + 'oauth2/default',
        clientId: Configuration.AUTH_CLIENT_ID,
        redirectUri: window.location.origin + '/callback',
        scopes,
        pkce: true,
        tokenManager: {
            autoRenew: true
        }
    })
}

const GetUserId = async () => {
    if (!(await oktaAuth.isAuthenticated())) {
        return ''
    }
    const tokenKey = Configuration.TOKEN_KEY
    const idToken = (await oktaAuth.tokenManager.get(tokenKey)) as IDToken
    return idToken?.claims?.sub ?? ''
}

async function redirectToSSU() {
    const redirectUrl = window.location.origin + '/callback'
    const SsuUrl = await createSession(redirectUrl)
    location.assign(SsuUrl)
}

function authInterceptor(): void {
    APIBroker.Instance.SetFilter((response: any) => {
        const token = oktaAuth.getAccessToken()
        if (token) {
            response.headers.common['Authorization'] = `Bearer ${token}`
        }
        return response
    })
}

export {redirectToSSU, InitOktaAuth, oktaAuth, GetUserId, authInterceptor}
