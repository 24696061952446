// @ts-check
import { defineStore } from 'pinia'
import { Coach } from '@/types/Coach'
import { WithId } from '@/types/General'
import constants from '@/utils/Constants.json'

export const coachStore = defineStore({
  id: 'coach',
  state: () => ({
    coaches: [] as (Coach & WithId)[]
  }),
  actions: {
    getById(id: string): (Coach & WithId) | undefined {
      return this.coaches.find((coach: Coach & WithId) => {
        return coach.id == id
      })
    },
    filterByName(criteria: string): (Coach & WithId)[] {
      return this.coaches.filter((coach: Coach & WithId) => {
        return coach.name.toUpperCase().includes(criteria.toUpperCase())
      })
    },
    filterByJurisdiction(criteria: string): (Coach & WithId)[] {
      return this.coaches.filter((coach: Coach & WithId) => {
        return coach.jurisdictions.join(', ').toUpperCase().includes(criteria.toUpperCase())
      })
    },
    filterByActive(criteria: string): (Coach & WithId)[] {
      return this.coaches.filter((coach: Coach & WithId) => {
        return (coach.isActive ? 'YES' : 'NO').includes(criteria.toUpperCase())
      })
    },
    filterByProduct(criteria: string): (Coach & WithId)[] {
      return this.coaches.filter((coach: Coach & WithId) => {
        const productsString = (products: string[]) => {
          return products.map((product: string) => {
            const found = constants.Products.find((x) => x.value === product)
            return found ? found.label : ''
          }).join(', ')
        }
        return productsString(coach.products).toUpperCase().includes(criteria.toUpperCase())
      })
    },
    filterByAll(criteria: string): (Coach & WithId)[] {
      const allResults = [
        ...this.filterByName(criteria),
        ...this.filterByJurisdiction(criteria),
        ...this.filterByProduct(criteria),
        ...this.filterByActive(criteria)
      ]

      // Removes duplicate entries
      return this.coaches.filter((coach: Coach & WithId) => allResults.includes(coach))
    },
    setCoaches(coaches: (Coach & WithId)[]) {
      this.$patch({
        coaches: coaches
      })
    },
    addCoach(coach: Coach & WithId) {

      this.$patch((state) => {
        state.coaches.push(coach)        
      })
    }    
  }
})
