import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e33cf56a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["for", "data-invalid", "data-disabled"]
const _hoisted_3 = { class: "input-group" }
const _hoisted_4 = {
  key: 0,
  class: "input-group-text"
}
const _hoisted_5 = ["id", "aria-describedby", "required", "value", "disabled"]
const _hoisted_6 = ["value"]
const _hoisted_7 = {
  key: 1,
  class: "input-group-text"
}
const _hoisted_8 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: _normalizeClass(["BsSelect form-control-helper", { 'bs-invalid-field': _ctx.dirty && _ctx.invalid }])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.selectId,
          class: "BsSelect__label form-label d-flex bs-field-label",
          "data-invalid": _ctx.dirty && _ctx.invalid,
          "data-disabled": _ctx.disabled
        }, _toDisplayString(_ctx.label), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.prepend)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.prepend), 1))
        : _createCommentVNode("", true),
      _createElementVNode("select", {
        id: _ctx.selectId,
        class: _normalizeClass(["BsSelect__select form-select", {
                    'is-invalid': _ctx.dirty && _ctx.invalid,
                }]),
        "aria-describedby": _ctx.dirty ? _ctx.invalidMessageId : null,
        required: _ctx.showRequiredAttr,
        value: _ctx.modelValue,
        disabled: _ctx.disabled,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
      }, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
            return (_openBlock(), _createElementBlock("option", {
              key: index,
              value: option.value
            }, _toDisplayString(option.text), 9, _hoisted_6))
          }), 128))
        ], true)
      ], 42, _hoisted_5),
      (_ctx.append)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.append), 1))
        : _createCommentVNode("", true),
      (_ctx.dirty)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            id: _ctx.invalidMessageId,
            class: "invalid-feedback"
          }, _toDisplayString(_ctx.invalidMessage), 9, _hoisted_8))
        : _createCommentVNode("", true)
    ])
  ], 10, _hoisted_1))
}