import { reactive, computed } from 'vue'
import type {
  IToastQueueStore,
  IToastQueueViewModel,
  IToastQueueActions,
  IToastItem,
  IToastItemArgsByType
} from './ToastQueue.interfaces'
import { provideToastQueueStore } from './ToastQueue.utils'
import locales from './ToastQueue.locales.en.json'

export * from './ToastQueue.interfaces'

export function useToastQueueStore(): IToastQueueStore {
  const state: IToastQueueViewModel = reactive({
    toasts: reactive([]),
    head: computed(() => state.toasts[0])
  })

  const actions: IToastQueueActions = {
    add,
    addSuccess,
    addError,
    addInfo,
    poll
  }

  function createDefaultToastItem(): IToastItem {
    return {
      type: 'success',
      position: 'bottom-center',
      title: '',
      message: ''
    }
  }

  function add(item: Partial<IToastItem>) {
    state.toasts.push({
      ...createDefaultToastItem(),
      ...item
    })
  }

  function addSuccess(item: IToastItemArgsByType) {
    state.toasts.push({
      ...createDefaultToastItem(),
      title: locales.success,
      ...item,
      type: 'success'
    })
  }

  function addError(item: IToastItemArgsByType) {
    state.toasts.push({
      ...createDefaultToastItem(),
      title: locales.error,
      ...item,
      type: 'failure'
    })
  }

  function addInfo(item: IToastItemArgsByType) {
    state.toasts.push({
      ...createDefaultToastItem(),
      title: locales.error,
      ...item,
      type: 'info'
    })
  }

  function poll() {
    state.toasts.shift()
  }

  return { state, actions }
}

export function autoProvideToastQueueStore() {
  const instance = useToastQueueStore()
  provideToastQueueStore(instance)
  return instance
}
