
import { defineComponent, ref, watch } from 'vue'
import BsDialog from '@/components/BsDialog/BsDialog.vue'

export default defineComponent({
  components: {
    BsDialog
  },
  props: {
    isVisible: {
      type: Boolean,
      default: true
    },
    disableDeleteButton: {
      type: Boolean,
      default: false
    },
    itemType: {
      type: String,
      required: true
    }
  },
  emits: ['close', 'delete'],
  setup(props, {emit}) {
    const isModalVisible = ref(false)

    watch(() => props.isVisible, (newValue) => {
      isModalVisible.value = (newValue)
    })

    return {
      emit,
      isModalVisible
    }
  }
})
