import type IPubSub from '../PubSub/IPubSup'
import PubSubProvider from '../PubSub/PubSubProvider'

enum PubSubs {
    PubSubJS,
}

class PubSubFactory {
    public static GetPubSub(type: PubSubs): IPubSub {
        switch (type) {
            case PubSubs.PubSubJS:
                return PubSubProvider.Instance

            default:
                throw new Error('pub sub provider Not implemented')
        }
    }
}

export { PubSubFactory, PubSubs }
