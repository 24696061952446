import '~/fonts/proxima-nova/stylesheet.css'
import '~/fonts/goldplay/stylesheet.css'
import { createApp } from 'vue'
import '@/styles/barbri-bootstrap/barbri-bootstrap.scss'
import 'bootstrap-icons/font/bootstrap-icons.scss'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import App from '@/App.vue'
import router from '@/router'
import { InitConfiguration } from '@/config/config'
import OktaVue from '@okta/okta-vue'
import {
    InitOktaAuth,
    authInterceptor,
    redirectToSSU,
    oktaAuth
} from '@/mid-layer/auth/okta-auth'
import PrimeVue from 'primevue/config'
import {createPinia} from 'pinia'

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'vue-cal/dist/vuecal.css'

(async () => {
    await InitConfiguration()

    InitOktaAuth()

    const app = createApp(App)
    app.use(createPinia())
    app.use(router)
    app.use(PrimeVue)
    app.use(OktaVue, {
        oktaAuth,
        onAuthRequired: async () => {
            await redirectToSSU()
        },
        onAuthResume: async () => {
            await redirectToSSU()
        }
    })

    authInterceptor()

    app.mount('#app')
})()
