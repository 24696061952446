
import { DefineComponent, defineComponent, ref, watch, PropType } from 'vue'
import BsDialog from '@/components/BsDialog/BsDialog.vue'
import VueCropper from 'vue-cropperjs'
import type Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'

export default defineComponent({
  components: {
    BsDialog,
    VueCropper
  },
  props: {
    isVisible: {
      type: Boolean,
      default: true
    },
    file: {
      type: Object as PropType<File | undefined>
    }
  },
  emits: ['close', 'on-image-submitted'],
  setup(props, {emit}) {
    const { showErrorToast } = useToast()

    const isModalVisible = ref(false)
    const pictureSource = ref('')
    const croppedPictureSource = ref('')
    const cropper = ref<InstanceType<DefineComponent> & Cropper>()

    watch(() => props.isVisible, (newValue) => {
      isModalVisible.value = (newValue)
    })

    watch(() => props.file, (newValue) => {
      if (newValue) {
        openFile(newValue)
      }
    })

    async function openFile(imageFile: File) {
      if (imageFile.type.indexOf('image/') === -1) {
        showErrorToast({
          message: 'File must be an image'
        })
        return
      }
      try {
        const result = await convertFileToDataURL(imageFile)
        isModalVisible.value = true
        pictureSource.value = result as string
        cropper.value?.replace(result as string)
      } catch (error: any) {
        showErrorToast({
          message: 'Error processing image file'
        })
      }
    }

    async function convertFileToDataURL(file: File) {
      return new Promise<string | ArrayBuffer>((resolve, reject) => {
        if (typeof FileReader === 'function') {
          const reader = new FileReader()
          reader.onload = (event) => {
            if (!event?.target?.result) {
              reject(new Error('Invalid result'))
              return
            }
            resolve(event.target.result)
          }
          reader.readAsDataURL(file)
        } else {
          reject(new Error('Sorry, FileReader API not supported'))
        }
      })
    }

    function closeModal() {
      croppedPictureSource.value = ''
      emit('close')
    }

    function cropImage() {
      croppedPictureSource.value = cropper.value?.getCroppedCanvas().toDataURL() ?? ''
    }

    async function handleImageSubmitted() {
      if (!croppedPictureSource.value) {
        showErrorToast({
          message: 'No picture available, please try again'
        })
        return
      }
      cropper.value?.getCroppedCanvas().toBlob((blob) => {
        if (blob) {
          emit('on-image-submitted', blob)
          closeModal()
        }
      }, props.file?.type, 0.8)
    }

    return {
      emit,
      isModalVisible,
      pictureSource,
      croppedPictureSource,
      cropper,
      closeModal,
      cropImage,
      handleImageSubmitted
    }
  }
})
