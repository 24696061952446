
import {
  defineComponent,
  ref,
  Ref,
  onMounted,
  watch,
  onBeforeMount,
  computed
} from 'vue'
import BsTabContainer from '@/components/BsTab/BsTabContainer.vue'
import BsTabList from '@/components/BsTab/BsTabList.vue'
import BsTabPanel from '@/components/BsTab/BsTabPanel.vue'
import BsTab from '@/components/BsTab/BsTab.vue'
import CounterCard from '@/components/CounterCard/CounterCard.vue'
import TableView from '@/components/Table/TableView.vue'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import ListEvents from '../Events/ListEvents.vue'
import ListLsatSessions from '../Lsat/ListLsatSessions.vue'
import { coachStore } from '@/stores/CoachStore'
import { WithId } from '@/types/General'
import { Coach } from '@/types/Coach'
import { BaseService } from '@/mid-layer/api-clients/BaseService'
import { EventModel, EventType, ProductSettings, SchoolAccount, Webinar, CourseTag } from '../Events/Events.interface'
import AddCoach from '@/components/AddCoach/AddCoach.vue'
import { injectAddCoachStore } from '@/components/AddCoach/AddCoach.store'
import AddEvent from '@/views/Events/AddEvent.vue'
import AddWebinar from '@/components/AddWebinar/AddWebinar.vue'
import EditCourseSettings from '@/components/EditCourseSettings/EditCourseSettings.vue'
import ExportEvents from '@/views/Events/ExportEvents.vue'
import constants from '../../utils/Constants.json'
import { PubSubFactory, PubSubs } from '@/mid-layer/Factories/PubSubFactory'
import {
  ApplyEventSearchCriteria,
  CoachListUpdated,
  EventListUpdated,
  WebinarListUpdated,
  OpenModalCourseSettings,
  CourseSettingsUpdated
} from '@/types/Topic'
import { injectEventsStore } from '../Events/Events.store'
import { getDateFromUTC, getProducts } from '../Events/Events.utils'
import 'bootstrap/js/dist/dropdown'
import eventLocales from '../Events/Events.locales.en.json'
import DeleteConfirmationModal from '@/components/Shared/DeleteConfirmationModal.vue'
import DeleteIcon from '@/components/icons/DeleteIcon.vue'
import { BarbriProduct } from '@/components/Header/Header.interface'
import TableLoader from '@/components/Table/TableLoader.vue'

interface PartialCoachModel {
  id: string;
  products: string;
  jurisdictions: string;
  name: string;
  active: string;
}

interface StoreState {
  coaches: (Coach & WithId)[];
}

export default defineComponent({
  components: {
    BsTabContainer,
    BsTabList,
    BsTabPanel,
    BsTab,
    BsTextField,
    CounterCard,
    TableView,
    ListEvents,
    ListLsatSessions,
    AddCoach,
    AddEvent,
    AddWebinar,
    ExportEvents,
    DeleteConfirmationModal,
    DeleteIcon,
    EditCourseSettings,
    TableLoader
  },
  props: {
    product: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const coachstore = coachStore()
    const addCoachstore = injectAddCoachStore()
    const eventStore = injectEventsStore()
    const coaches: Ref<(Coach & WithId)[]> = ref([])
    const coachData = ref<PartialCoachModel[]>([])
    const events = ref<EventModel[]>([])
    const webinars = ref<Webinar[]>([])
    const schools = ref<SchoolAccount[]>([])
    const productSettings = ref<ProductSettings[]>()
    const courseTags = ref<CourseTag[]>()
    const isEventsLoading = ref<boolean>(true)
    const isLoading = ref(true)
    const selectedTab = ref('events')
    const currentEditDetails = ref<(Coach & WithId) | null | undefined>(null)
    const currentWebinarEditDetails = ref<(EventModel) | null | undefined>(null)
    const currentEventEditDetails = ref<
      (EventModel) | null | undefined
    >(null)
    const isWebinarModalOpen = ref<boolean>(false)
    const isCoachModalOpen = ref<boolean>(false)
    const isCourseSettingsModalOpen = ref<boolean>(false)
    const eventsSearchText = ref<string>('')
    const coachSearchText = ref<string>('')
    const webinarSearchText = ref<string>('')
    const eventsTotal = ref(0)
    const isAdmin = ref(false)
    const isValidatingAdmin = ref<boolean>(true)
    const visibleWebinarDeleteModal = ref<boolean>(false)
    const disableAcceptWebinarDelete = ref<boolean>(false)
    const showSuccess = ref<boolean>(false)
    let webinarToDelete = ''
    const selectedCoachProfilePicture = ref<Blob | undefined>(undefined)

    const coachesColumns = [
      { label: 'Coach', key: 'name', width: '300' },
      { label: 'Product', key: 'products', width: '400' },
      { label: 'Jurisdiction', key: 'jurisdictions' },
      { label: 'Active', key: 'active' }
    ]

    const webinarColumns = [
      { label: 'Webinar Title', key: 'title' },
      { label: 'Date/Time', key: 'dateTime' },
      { label: 'Product', key: 'products' },
      { label: 'Jurisdiction', key: 'jurisdictions' },
      { label: 'Link', key: 'link' },
      { label: 'Hidden', key: 'hidden' }
    ]

    const service: BaseService<Coach & WithId> = new BaseService<Coach & WithId>('coaches')

    const ensureAdmin = new BaseService('ensureadmin')

    const pubSub = PubSubFactory.GetPubSub(PubSubs.PubSubJS)

    const formatCoachData = () => {
      return coaches.value.map((coach: Coach) => ({
        name: coach.name,
        products: coach.products
          ? coach.products
              .map((product: string) => {
                const found = constants.Products.find(
                  (x) => x.value === product
                )
                return found ? found.label : ''
              })
              .filter((formatted) => formatted != '')
              .join(', ')
          : '',
        jurisdictions: coach.jurisdictions
          ? coach.jurisdictions.join(', ')
          : '',
        id: coach.id,
        active: coach.isActive ? 'Y': 'N'
      }))
    }

    onBeforeMount(async () => {
      try {
        await ensureAdmin.Get()
        isAdmin.value = true
      } catch (e) {
        console.log(e)
      } finally {
        isValidatingAdmin.value = false
      }
    })

    onMounted(async () => {
      coachstore.$subscribe((mutation: unknown, stateChanged: StoreState) => {
        coaches.value = stateChanged.coaches
      })
      try {
        subscribePubSubEvents()
        await loadEventData()
        await loadCoachData()
        await loadSchoolData()
        await loadCourseSettingsData()
        handleProductSwitch()
        isLoading.value = false
      } catch (e) {
        console.log('error', e)
      }
    })

    const subscribePubSubEvents = () => {
      pubSub.Subscribe(CoachListUpdated, async () => {
        await loadCoachData()
        if (coachSearchText.value.trim() !== '') {
          onCoachFilterChange()
        }
      })
      pubSub.Subscribe(EventListUpdated, async () => {
        await loadEventData()
        pubSub.Publish(ApplyEventSearchCriteria)
      })
      pubSub.Subscribe(WebinarListUpdated, async () => {
        await loadEventData()
        pubSub.Publish(ApplyEventSearchCriteria)
      })
      pubSub.Subscribe(OpenModalCourseSettings, async () => {
        if (!productSettings.value)
        {
          await loadCourseSettingsData()
        }
        isCourseSettingsModalOpen.value = true
      })
      pubSub.Subscribe(CourseSettingsUpdated, async () => {
        await loadCourseSettingsData()
      })
    }

    const loadEventData = async () => {
      try {
        await eventStore.actions.getEvents()
        filterEventsByProduct()
        webinars.value = formatWebinars(eventStore.state.events.filter(event => event.eventType === EventType.Webinar))
      } finally {
        isEventsLoading.value = false
      }
      return eventStore.state.events
    }

    const formatWebinars = (webinars: EventModel[]) => {
      return webinars.map(webinar => {
        return {
          id: webinar.id,
          title: webinar.description,
          dateTime: webinar.startDate + ' ' + webinar.startTime,
          products: getProducts(webinar),
          jurisdictions: webinar.jurisdictions?.join(', ') ?? '',
          link: webinar.meetingLink,
          hidden: webinar.isHidden ? 'Y' : 'N',
          event: webinar
        } as Webinar}
      )
    }

    const loadCoachData = async () => {
      coachstore.setCoaches(await service.Get())
      filterCoachesByProduct()
    }

    const loadSchoolData = async () => {
      await eventStore.actions.getSchools()
      schools.value = eventStore.state.schools.sort((a, b) => a.name.localeCompare(b.name) )
    }

    const loadCourseSettingsData = async () => {
      await loadProductData()
      await loadTagData()
    }

    const loadProductData = async () => {
      await eventStore.actions.getProducts()
      productSettings.value = eventStore.state.productSettings
    }

    const loadTagData = async () => {
      await eventStore.actions.getTags()
      courseTags.value = eventStore.state.courseTags
    }

    const handleCoachEdit = async (rowData: Coach) => {
      const coach = coaches.value.find(
        (coach: Coach) => coach.id === rowData.id
      )
      try {
        selectedCoachProfilePicture.value = await service.GetProfilePicture(rowData.id)
      } catch (error) {
        selectedCoachProfilePicture.value = undefined
      }
      currentEditDetails.value = Object.assign({}, coach)
      isCoachModalOpen.value = true
    }

    const handleCoachInactivate = async (rowData: Coach) => {
      const coach = coaches.value.find(
        (coach: Coach) => coach.id === rowData.id
      )
      if (!coach) {
        return
      }
      coach.isActive = false
      try {
        await addCoachstore.actions.editCoach(coach.id, coach, undefined)
      } catch(ex) {
        coach.isActive = true
      }
      coachData.value = formatCoachData()
    }

    const handleWebinarEdit = (rowData: Webinar) => {
      const webinar = webinars.value.find(
        (webinar: Webinar) => webinar.event.id === rowData.event.id
      )
      currentWebinarEditDetails.value = Object.assign({}, webinar?.event)
      isWebinarModalOpen.value = true
    }

    const handleTabSelected = (tab: string) => {
      selectedTab.value = tab
    }

    const handleAdd = () => {
      if (selectedTab.value === 'coaches') {
        isCoachModalOpen.value = true
        isWebinarModalOpen.value = false
      }
      else if (selectedTab.value === 'webinars') {
        currentWebinarEditDetails.value = null
        isWebinarModalOpen.value = true
        isCoachModalOpen.value = false
      }
    }

    const handleEventSelected = (event: EventModel) => {
      currentEventEditDetails.value = event
    }

    const resetDetails = () => {
      currentEventEditDetails.value = null
    }

    watch(coachSearchText, () => { onCoachFilterChange() })
    watch(webinarSearchText, () => { onWebinarFilterChange() })

    watch(
      () => eventStore.state.events,
      (newValue: (EventModel)[]) => {
        events.value = newValue
      }
    )

    const onCoachFilterChange = () => {
      if (barbriProduct.value === BarbriProduct.PowerScore) {
        coaches.value = coachstore.filterByAll(coachSearchText.value.trim()).filter(coach =>
          coach.products.some(prod => prod == constants.LSATValue))
        coachData.value = formatCoachData()
      }
      else {
        coaches.value = coachstore.filterByAll(coachSearchText.value.trim()).filter(coach =>
          coach.products.some(prod => prod != constants.LSATValue))
        coachData.value = formatCoachData()
      }
    }

    const onWebinarFilterChange = () => {
      const rawWebinars = formatWebinars(eventStore.state.events.filter(event => event.eventType === EventType.Webinar))
      const formattedSearchText = webinarSearchText.value.trim().toUpperCase()

      const filteredByTitle = rawWebinars.filter(webinar => webinar.title.toUpperCase().includes(formattedSearchText))
      const filteredByDateTime = rawWebinars.filter(webinar => formatWebinarDate(webinar).toUpperCase().includes(formattedSearchText))
      const filteredByProduct = rawWebinars.filter(webinar => productsString(webinar.event.products).toUpperCase().includes(formattedSearchText))
      const filteredByJurisdiction = rawWebinars.filter(webinar => webinar.event.jurisdictions.join(', ').includes(formattedSearchText))
      const filteredByLink = rawWebinars.filter(webinar => webinar.link.toUpperCase().includes(formattedSearchText))
      const filteredByHidden = rawWebinars.filter(webinar => (webinar.hidden == 'Y' ? 'YES' : 'NO').includes(formattedSearchText))

      // Removes duplicate entries
      const allResults = [ ...filteredByTitle, ...filteredByDateTime, ...filteredByLink, 
      ...filteredByHidden, ...filteredByProduct, ...filteredByJurisdiction ]

      const ids = allResults.map(res => res.event.id)
      webinars.value = rawWebinars.filter(webinar => ids.includes(webinar.event.id))
    }

    const productsString = (products: string[]) => {
      return products.map((product: string) => {
        const found = constants.Products.find((x) => x.value === product)
        return found ? found.label : ''
      }).join(', ')
    }

    const updateEventsCount = (totalEvents: number) => {
      eventsTotal.value = totalEvents
    }

    function formatWebinarDate(rowData: Webinar) {
      const startDate = getDateFromUTC(rowData.event.startDate, rowData.event.startTime, rowData.event.timeZone)
      const endDate = getDateFromUTC(rowData.event.endDate, rowData.event.endTime, rowData.event.timeZone)

      return startDate.toLocaleString({month: 'short', day: '2-digit', year: 'numeric'})
            + ' | '
            + startDate.toLocaleString({ hour: 'numeric', minute: '2-digit' })
            + ' - '
            + endDate.toLocaleString({ hour: 'numeric', minute: '2-digit' })
            + ' '
            + rowData.event.timeZone.toUpperCase()
    }

    async function handleWebinarDeleteClick(event: Event, record: EventModel) {
      event.stopPropagation()
      webinarToDelete = record?.id ?? ''
      if (webinarToDelete != '')
      {
        visibleWebinarDeleteModal.value = true
      }
    }

    async function onAcceptWebinarDelete() {
      try {
        disableAcceptWebinarDelete.value = true
        await eventStore.actions.deleteEvent(webinarToDelete)
        pubSub.Publish(EventListUpdated)
        pubSub.Publish(ApplyEventSearchCriteria)
        visibleWebinarDeleteModal.value = false
        disableAcceptWebinarDelete.value = false
        webinarToDelete = ''
      } catch (error) {
        disableAcceptWebinarDelete.value = false
      }
      showSuccess.value = true
      setTimeout(function() {
        showSuccess.value = false
      }, 500)
    }

    watch(isCoachModalOpen, () => {
      if (!isCoachModalOpen.value) {
        currentEditDetails.value = null
      }
    })

    const computeAllCoaches = computed(() => coachstore.coaches)

    const barbriProduct = computed(() => props.product as BarbriProduct)

    watch(barbriProduct, () => {
      handleProductSwitch()
    })

    function handleProductSwitch() {
      filterEventsByProduct()
      filterCoachesByProduct()
      eventsSearchText.value = ''
      coachSearchText.value = ''
      webinarSearchText.value = ''
      handleTabSelected('events')
    }

    function getCurrentProductGrouping() {
      if (barbriProduct.value === BarbriProduct.BarReview) {
        return constants.BarReviewGrouping
      } else if (barbriProduct.value === BarbriProduct.PowerScore) {
        return constants.LSATGrouping
      }
    }

    function filterEventsByProduct() {
      events.value = eventStore.state.events.filter(event => event.eventType === EventType.CoachingSession && event.products.some(productId => {
        return constants.Products.some(product => 
        product.value === productId && product.grouping === getCurrentProductGrouping())
      }))
      eventsTotal.value = events.value.length
    }

    function filterCoachesByProduct() {
      coaches.value = coachstore.coaches.filter(coach => coach.products.some(productId => {
        return constants.Products.some(product => 
        product.value === productId && product.grouping === getCurrentProductGrouping())
      }))
      coachData.value = formatCoachData()
    }

    return {
      coachesColumns,
      coachData,
      computeAllCoaches,
      webinarColumns,
      webinars,
      isLoading,
      events,
      eventLocales,
      schools,
      productSettings,
      courseTags,
      isEventsLoading,
      isCoachModalOpen,
      isWebinarModalOpen,
      isCourseSettingsModalOpen,
      eventsSearchText,
      selectedTab,
      currentEditDetails,
      currentWebinarEditDetails,
      currentEventEditDetails,
      handleCoachEdit,
      handleWebinarEdit,
      handleTabSelected,
      handleAdd,
      handleEventSelected,
      resetDetails,
      updateEventsCount,
      handleCoachInactivate,
      eventsTotal,
      isAdmin,
      isValidatingAdmin,
      coachSearchText,
      webinarSearchText,
      visibleWebinarDeleteModal,
      showSuccess,
      disableAcceptWebinarDelete,
      formatWebinarDate,
      handleWebinarDeleteClick,
      onAcceptWebinarDelete,
      selectedCoachProfilePicture,
      BarbriProduct,
      barbriProduct
    }
  }
})
