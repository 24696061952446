
import { defineComponent, PropType, computed, unref } from 'vue'
import type { Validation } from '@vuelidate/core'
import { createAutoIncrementIdProp } from '@/utils/VueTools'

export const BsCheckbox = defineComponent({
  name: 'BsCheckbox',
  props: {
    id: createAutoIncrementIdProp('BsCheckbox'),
    modelValue: Boolean as PropType<boolean>,
    label: String as PropType<string>,
    disabled: Boolean as PropType<boolean>,
    validation: Object as PropType<Validation>
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const dirty = computed<boolean>(() => {
      return props.validation?.$dirty ?? false
    })
    const invalid = computed<boolean>(() => {
      return props.validation?.$invalid ?? false
    })
    const invalidMessage = computed<string>(() => {
      const message = props.validation?.$errors?.[0]?.$message ?? ''
      return unref(message)
    })

    const invalidMessageId = computed(() => props.id + '__invalid_message')

    function handleChange(event: Event) {
      const target = event.target as HTMLInputElement
      emit('update:modelValue', target.checked)
    }

    return {
      dirty,
      invalid,
      invalidMessage,
      invalidMessageId,
      handleChange
    }
  }
})

export default BsCheckbox
