import { AxiosError } from 'axios'

interface ErrorPayload<T> {
    type: ErrorType,
    message: string,
    data: T | null
}

type ConflictingEventSchedule = string[]

type ConflictingEventAttendee = {
    attendee: string,
    events: string[]
}

type ValidationError = {
    errorMessage: string
    memberNames: string[]
}

interface ErrorAdditions extends AxiosError {
    data:  ErrorPayload<Array<ConflictingEventAttendee>|ConflictingEventSchedule|Array<ValidationError>>,
    status: number
}

enum ErrorType {
  ConflictingEventAttendee = 'ConflictingEventAttendee',
  ConflictingEvent = 'ConflictingEvent',
  Unauthorized = 'Unauthorized',
  ValidationError = 'ValidationError',
  UniqueViolation = 'UniqueViolation',
  UnauthorizedAttendee = 'UnauthorizedAttendee',
  AddedAttendeeToExpiredEvent = 'AddedAttendeeToExpiredEvent',
  MaximumAttendeesViolated = 'MaximumAttendeesViolated',
  OutdatedDataRefreshNeeded = 'OutdatedDataRefreshNeeded',
  InvalidClassId = 'InvalidClassId',
  SalesforceError = 'SalesforceError',
}

export { ErrorPayload, ConflictingEventSchedule, ConflictingEventAttendee, ValidationError, ErrorAdditions, ErrorType }