import { reactive, computed, nextTick } from 'vue'
import type {
  IBsTabContainerViewModel,
  IBsTabContainerActions
} from './BsTab.interfaces'

export function createBsTabStore() {
  const state: IBsTabContainerViewModel = reactive({
    tabs: [],
    selectedTab: computed(computeSelectedTab),
    focusedTab: computed(computeFocusedTab),
    lastTab: computed(computeLastTab),
    active: false
  })

  const actions: IBsTabContainerActions = {
    addTab,
    selectTab,
    focusTab,
    focusNextTab,
    focusPrevTab,
    focusReset,
    removeTab,
    activate,
    deactivate
  }

  function addTab(tabId: string) {
    state.tabs = [...state.tabs, { id: tabId }]

    if (state.tabs.length === 1) {
      nextTick(() => {
        selectTab(tabId)
        focusTab(tabId)
      })
    }
  }

  function selectTab(tabId: string) {
    state.selectedTabId = tabId
  }

  function focusTab(tabId: string) {
    state.focusedTabId = tabId
  }

  function focusNextTab() {
    if (state.focusedTab) {
      const index = state.tabs.indexOf(state.focusedTab)
      const nextIndex = state.tabs[index + 1] ? index + 1 : 0
      state.focusedTabId = state.tabs[nextIndex].id
    }
  }

  function focusPrevTab() {
    if (state.focusedTab) {
      const index = state.tabs.indexOf(state.focusedTab)
      const nextIndex = state.tabs[index - 1]
          ? index - 1
          : state.tabs.length - 1
      state.focusedTabId = state.tabs[nextIndex].id
    }
  }

  function focusReset() {
    state.focusedTabId = state.selectedTabId
  }

  function removeTab(tabId: string) {
    state.tabs = state.tabs.filter((tab) => tab.id !== tabId)
  }

  function activate() {
    state.active = true
  }

  function deactivate() {
    state.active = false
  }

  function computeSelectedTab() {
    return state.tabs.find((tab) => tab.id === state.selectedTabId)
  }

  function computeFocusedTab() {
    return state.tabs.find((tab) => tab.id === state.focusedTabId)
  }

  function computeLastTab() {
    return state.tabs[state.tabs.length - 1]
  }

  return { state, actions }
}

export default createBsTabStore
