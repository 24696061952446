import axios from 'axios'

interface IConfig {
    [key: string]: string;
    APP_TITLE: string;
    REST_API: string;
    AUTH_CLIENT_ID: string;
    AUTH_ENDPOINT: string;
    TOKEN_KEY: string;
    MAX_STUDENTS: string;
}

interface JsonFile {
    [key: string]: string;
}

let Configuration: IConfig = {} as IConfig

const InitConfiguration = async (): Promise<void> => {
    const response = await axios.get('/config.values.json')
    const configValues: JsonFile = response.status === 404 ? {} : response.data
    const config = {} as IConfig
    for (const value in configValues) {
        config[value] = parse(
            configValues[value],
            process.env[`VUE_APP_${value}`]
        )
    }
    Configuration = config
}

function parse(value: string, fallback: boolean | number | string | undefined) {
    if (typeof value === 'undefined' || value === '') {
        return fallback
    }
    switch (typeof fallback) {
        case 'boolean':
            return !!JSON.parse(value)
        case 'number':
            return JSON.parse(value)
        default:
            return value
    }
}

export { InitConfiguration, Configuration }
