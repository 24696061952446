import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d60b8d3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "rectangle" }
const _hoisted_2 = { class: "ms-4 text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["circle", { 'selected': _ctx.selected }])
    }, _toDisplayString(_ctx.count), 3),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.text), 1)
  ]))
}