import type IPubSub from './IPubSup'
import PubSub from 'pubsub-js'

class PubSubProvider implements IPubSub {
    private static _instance: PubSubProvider;

    public static get Instance() {
        if (!this._instance) this._instance = new PubSubProvider()

        return this._instance
    }

    Publish<T>(topic: string, message?: T): void {
        PubSub.publish(topic as PubSubJS.Message, message)
    }

    Subscribe<T>(
        topic: string,
        callback: (message: string, data?: T) => void
    ): string {
        return PubSub.subscribe(topic as PubSubJS.Message, callback)
    }

    Unsubscribe(token: string): void {
        PubSub.unsubscribe(token)
    }
}

export default PubSubProvider
