import axios, {AxiosRequestConfig, type AxiosInstance} from 'axios'
import {Configuration} from '@/config/config'
import router from '@/router'

class APIBroker {
    private readonly _ACCESS_CONTROL_HEADER = 'Access-Control-Allow-Origin';
    private readonly _ACCESS_CONTROL_ORIGIN = '*';
    private readonly _CONTENT_TYPE_HEADER = 'Content-Type';
    private readonly _CONTENT_TYPE_JSON = 'application/json';
    private _REST_API: string;

    private brokerInstance: AxiosInstance;

    private constructor() {
        this._REST_API = Configuration.REST_API
        this.brokerInstance = axios.create({
            baseURL: this._REST_API,
            validateStatus: function () {
                return true
            }
        })
        this.brokerInstance.defaults.headers.common[
            this._ACCESS_CONTROL_HEADER
            ] = this._ACCESS_CONTROL_ORIGIN
        this.brokerInstance.defaults.headers.post[this._CONTENT_TYPE_HEADER] =
            this._CONTENT_TYPE_JSON
        this.brokerInstance.defaults.headers.put[this._CONTENT_TYPE_HEADER] =
            this._CONTENT_TYPE_JSON
        this.brokerInstance.defaults.headers.patch[this._CONTENT_TYPE_HEADER] =
            this._CONTENT_TYPE_JSON
        this.brokerInstance.defaults.headers.get[this._CONTENT_TYPE_HEADER] =
            this._CONTENT_TYPE_JSON
        this.brokerInstance.interceptors.response.use(APIBroker.onErrorResponse)
    }

    private static _instance: APIBroker;

    public async Post<Payload,Response>(
        resource: string,
        payload: Payload,
        baseURL?: string
    ): Promise<Response> {
        const response = await this.brokerInstance.post(
            (baseURL ?? this._REST_API) + resource,
            payload
        )
        if (response.status >= 200 && response.status <= 299)
            return Promise.resolve(response.data)
        else
            return Promise.reject(response)
    }

    public async Put<Payload,Response>(
        resource: string,
        id: string,
        payload: Payload,
        baseURL?: string,
        headers?: {[name:string]:string}
    ): Promise<Response> {

        const response = await this.brokerInstance.put(
            (baseURL ?? this._REST_API) + resource + `/${id}`,
            payload,
            {
                headers: {...headers}
            }
        )
        if (response.status >= 200 && response.status <= 299)
            return Promise.resolve(response.data)
        else
            return Promise.reject(response)
    }

    public async Get<Response>(resource: string, baseURL?: string, config?: AxiosRequestConfig): Promise<Response> {
        const response = await this.brokerInstance.get(
            (baseURL ?? this._REST_API) + resource,
            config
        )
        if (response.status >= 200 && response.status <= 299)
            return Promise.resolve(response.data)
        else
            return Promise.reject(response.data)
    }

    public async GetById<Response>(resource: string, id: string, baseURL?: string): Promise<Response> {
        const response = await this.brokerInstance.get(
            (baseURL ?? this._REST_API) + resource + `/${id}`
        )
        if (response.status >= 200 && response.status <= 299)
            return Promise.resolve(response.data)
        else
            return Promise.reject(response.data)
    }

    public async Patch<Payload,Response>(
        resource: string,
        payload: Payload,
        baseURL?: string
    ): Promise<Response> {
        const response = await this.brokerInstance.patch(
            (baseURL ?? this._REST_API) + resource,
            payload
        )
        if (response.status >= 200 && response.status <= 299)
            return Promise.resolve(response.data)
        else
            return Promise.reject(response.data)
    }

    public async Delete(
        resource: string,
        id: string,
        baseURL?: string
    ): Promise<any> {
        const response = await this.brokerInstance.delete(
            (baseURL ?? this._REST_API) + resource + `/${id}`
        )

        if (response.status >= 200 && response.status <= 299)
            return Promise.resolve(response.data)
        else
            return Promise.reject(response.data)
    }

    private static onErrorResponse(response: any): any {
        if (response.status == 401) {
            router.push({name: 'unauthorized'})
            return
        } else if (response.status == 403) {
            router.push({name: 'forbidden'})
            return
        }
        return response
    }

    public SetFilter(filter: (config: any) => any): void {
        this.brokerInstance.interceptors.request.use(filter)
    }

    public static get Instance(): APIBroker {
        if (!this._instance) {
            this._instance = new APIBroker()
        }
        return this._instance
    }
}

export default APIBroker
