
import { PropType, ref, defineComponent } from 'vue'
import type {
  DropdownOptionModel,
  UserDetailsModel
} from '@/components/Header/Header.interface'
import UserNameIcon from '@/components/Header/UsernameIcon.vue'
import { useOutsideClick } from '@/utils/HandleOutsideClick'
import { useUserStore } from '@/stores/UserStore'
import BsSpinner from '@/components/BsSpinner/BsSpinner.vue'
import {OpenModalCourseSettings} from '@/types/Topic'
import {PubSubFactory, PubSubs} from '@/mid-layer/Factories/PubSubFactory'

const HeaderDropdown = defineComponent({
  name: 'HeaderDropdown',
  props: {
    nameDetails: {
      type: Object as PropType<UserDetailsModel>
    },
    options: {
      type: Array as PropType<DropdownOptionModel[]>,
      required: true
    }
  },
  components: { UserNameIcon, BsSpinner },
  setup(props) {
    const dropdownRef = ref<HTMLDivElement>()
    const dropdownOptions = ref(props.options)

    const { isOpen, handleOpen, handleClose } = useOutsideClick(dropdownRef)

    const pubSub = PubSubFactory.GetPubSub(PubSubs.PubSubJS)

    function handleDropdownChange(value: boolean) {
      props.options.forEach(val => {
        val.loading = false
      })

      if (value) {
        handleOpen()
      }
    }

    function handleOptionClick(e: Event, val: DropdownOptionModel) {
      //handles the dropdown options change

      switch (val.key) {
        case 'logout':
          useUserStore().logout()
          val.loading = true
          return
        case 'course-settings':
          pubSub.Publish(OpenModalCourseSettings)
          val.loading = true
          return
      }

      handleClose()
    }

    return {
      handleDropdownChange,
      handleOptionClick,
      isOpen,
      dropdownRef,
      dropdownOptions
    }
  }
})

export default HeaderDropdown
