import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-edf3b51a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "flex-fill d-flex justify-content-start align-items-center"
}
const _hoisted_2 = {
  key: 1,
  class: "flex-fill d-flex justify-content-end align-items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "tablist",
    role: "tablist",
    class: "BsTabList",
    onFocusin: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleTabListFocusIn && _ctx.handleTabListFocusIn(...args))),
    onFocusout: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleTabListFocusOut && _ctx.handleTabListFocusOut(...args)))
  }, [
    (_ctx.existLeft)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "left", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.existRight)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "right", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 544))
}