import { reactive, inject, provide } from 'vue'
import { BaseService } from '@/mid-layer/api-clients/BaseService'
import {
  EventModel,
  IEventsActions,
  IEventsStore,
  IEventsState,
  LabelValue,
  AddEventModel,
  EventType,
  SchoolAccount,
  ProductList,
  ProductSettings,
  TagList,
  CourseTag
} from './Events.interface'
import locales from './Events.locales.en.json'
import { Coach } from '@/types/Coach'
import DefaultConstants from '@/utils/Constants.json'
import {
  ConflictingEventAttendee,
  ErrorPayload,
  ErrorType
} from '@/mid-layer/api-clients/ApiExceptions'
import useHttpWrapper from '@/stores/HttpWrapper'
import constants from '@/utils/Constants.json'

export function createEventsStore(): IEventsStore {
  const httpWrapper = useHttpWrapper(errorToMessage)

  const service = new BaseService<EventModel>('events')
  const productConstants = constants.Products

  const state: IEventsState = reactive({
    events: [],
    products: [],
    jurisdictions: [],
    schools: [],
    productSettings: [],
    courseTags: []
  })

  function errorToMessage(error: ErrorPayload<any>) {
    switch (error.type) {
      case ErrorType.ConflictingEventAttendee: {
        const conflicts = error.data as Array<ConflictingEventAttendee>
        return `${locales.event_attendee_in_other_event}${conflicts
          .map((value) => value.attendee)
          .join(', ')}`
      }
      case ErrorType.ConflictingEvent:
        return locales.event_other_event_at_same_time
      case ErrorType.SalesforceError:
        return locales.event_salesforce_failure
    }
  }

  function filterProductsAndJurisdictions(coachId: string, coaches: Coach[]) {
    if (!coachId) {
      state.products = []
      state.jurisdictions = []
      return
    }

    const getCoachItem = coaches.find((val) => val.id === coachId)

    const filterElements = (
      arr: LabelValue[],
      coachInfo: string[] | undefined
    ) =>
      arr.filter((val) => {
        return (coachInfo || []).includes(val.value)
      })

    state.jurisdictions = filterElements(
      DefaultConstants.Jurisdictions,
      getCoachItem?.jurisdictions
    )

    state.products = filterElements(
      DefaultConstants.Products,
      getCoachItem?.products
    )
  }

  const actions: IEventsActions = {
    addEvent,
    editEvent,
    deleteEvent,
    getEvents,
    getSchools,
    filterProductsAndJurisdictions,
    getProducts,
    updateProducts,
    getTags,
    updateTags,
    getEventDocument
  }

  async function addEvent(val: AddEventModel, documentFiles: File[]) {
    const formData = new FormData()
    formData.append('event', JSON.stringify(val.newEvent))
    formData.append('event_type', val.eventType)
    if (documentFiles) {
      documentFiles.forEach(file => {
        formData.append(file.name, file)
      })
    }

    const eventService = new BaseService<FormData>('events')

    return await httpWrapper(
      locales.event_added_success,
      locales.event_add_failure,
      async () => await eventService.Create(formData)
    )
  }

  async function editEvent(
    eventId: string,
    val: EventModel,
    newDocumentFiles: File[],
    removedDocumentFileNames: string[],
    headers?: { [name: string]: string }
  ) {
    const formData = new FormData()
    formData.append('event', JSON.stringify(val))
    if (newDocumentFiles) {
      newDocumentFiles.forEach(file => {
        formData.append(file.name, file)
      })
    }
    if (removedDocumentFileNames) {
      formData.append('delete_documents', JSON.stringify(removedDocumentFileNames))
    }

    const eventService = new BaseService<FormData>('events')

    return await httpWrapper(
      locales.event_updated_success,
      locales.event_update_failure,
      async () => await eventService.Update(eventId, formData, headers)
    )
  }

  async function deleteEvent(id: string) {
    return await httpWrapper(
      locales.event_deleted_success,
      locales.event_deleted_failure,
      async () => await service.Delete(id)
    )
  }

  async function getEvents(): Promise<void> {
    state.events = (await service.Get()).map((element) => {
      if (!element.tags) element.tags = []
      if (!element.documentFileNames) element.documentFileNames = []
      element.eventType = element.eventType.toString() == 'Webinar' ? EventType.Webinar : EventType.CoachingSession
      return element
    })
  }

  async function getSchools(): Promise<void> {
    const service = new BaseService<SchoolAccount>('schools')
    state.schools = await service.Get()
  }

  async function getProducts(): Promise<void> {
    const service = new BaseService<ProductList>('products')
    const productList = await service.GetSingular()

    state.productSettings = productConstants.map(pc => MapProductSettings(productList, pc))
  }

  async function updateProducts(val: ProductSettings[]) {
    const eventService = new BaseService<ProductList>('products')

    const productList: ProductList =
    {
      productSettings: val
    }

    return await httpWrapper(
      locales.course_settings_update_success,
      locales.course_settings_update_failure,
      async () => await eventService.Create(productList)
    )
  }

  async function getTags(): Promise<void> {
    const service = new BaseService<TagList>('tags')
    state.courseTags = (await service.GetSingular()).courseTags
  }

  async function updateTags(val: CourseTag[]) {
    const eventService = new BaseService<TagList>('tags')

    const tagList: TagList =
    {
      courseTags: val
    }

    return await httpWrapper(
      locales.course_settings_update_success,
      locales.course_settings_update_failure,
      async () => await eventService.Create(tagList)
    )
  }

  async function getEventDocument(eventId: string, documentName: string): Promise<Blob | undefined> {
    return await service.GetEventDocument(eventId, documentName)
  }

  function MapProductSettings(productList: ProductList, productConstant: any) {
    const matchingSetting = productList.productSettings.find(setting => setting.id == productConstant.value)
      if (matchingSetting)
      {
        matchingSetting.name = productConstant.label
        matchingSetting.grouping = productConstant.grouping

        return matchingSetting
      }

      const newSettings: ProductSettings =
      {
        id: productConstant.value,
        grouping: productConstant.grouping,
        name: productConstant.label,
        maxAttendees: constants.MaxAttendeesLength
      }

      return newSettings
  }

  return {
    state,
    actions
  }
}

const IEventsStoreSymbol = Symbol('IEventsStore')

export function injectEventsStore() {
  return inject(IEventsStoreSymbol, () => createEventsStore(), true)
}

export function provideEventsStore(store: IEventsStore) {
  provide(IEventsStoreSymbol, store)
}
