// @ts-check
import { defineStore } from 'pinia'
import { oktaAuth } from '@/mid-layer/auth/okta-auth'
import { UserClaims } from '@okta/okta-auth-js'
import { PubSubFactory, PubSubs } from '@/mid-layer/Factories/PubSubFactory'

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    name: '',
    email: '',
    isAuthenticated: false
  }),
  actions: {
    async logout() {

      await oktaAuth.signOut()

      this.$patch({
        name: '',
        email: '',
        isAuthenticated: false
      })
    },
    async login(user: UserClaims) {
      this.$patch({
        name: user.name,
        email: user.email,
        isAuthenticated: true
      })
    }
  }
})
