
import { defineComponent, PropType } from 'vue'
import { ProductSettings } from '@/views/Events/Events.interface'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import { isOutOfRange } from './EditCourseSettings.interface'

export default defineComponent({
  components: {
    BsTextField
  },
  props: {
    productDictionary: {
      type: Object as PropType<ProductSettings[]>,
      default: () => null
    }
  },
  setup() {
    function generateErrortext(maxAttendees: number) {
      if (maxAttendees <= 0)
      {
        return '(Value must be > 0)'
      }
      else if (maxAttendees >= 100000)
      {
        return '(Value must be < 100k)'
      }
    }

    return {
      isOutOfRange,
      generateErrortext
    }
  }
})
