<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_333_851" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
<rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_333_851)">
<path d="M5.30773 20.5C4.8026 20.5 4.37503 20.325 4.02503 19.975C3.67503 19.625 3.50003 19.1974 3.50003 18.6923V15.1346H5.00001V18.6923C5.00001 18.7692 5.03206 18.8397 5.09616 18.9038C5.16027 18.9679 5.2308 19 5.30773 19H18.6923C18.7692 19 18.8397 18.9679 18.9039 18.9038C18.968 18.8397 19 18.7692 19 18.6923V5.3077C19 5.23077 18.968 5.16024 18.9039 5.09613C18.8397 5.03203 18.7692 4.99998 18.6923 4.99998H5.30773C5.2308 4.99998 5.16027 5.03203 5.09616 5.09613C5.03206 5.16024 5.00001 5.23077 5.00001 5.3077V8.86535H3.50003V5.3077C3.50003 4.80257 3.67503 4.375 4.02503 4.025C4.37503 3.675 4.8026 3.5 5.30773 3.5H18.6923C19.1974 3.5 19.625 3.675 19.975 4.025C20.325 4.375 20.5 4.80257 20.5 5.3077V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H5.30773ZM10.6731 16.4615L9.61928 15.3769L12.2462 12.75H3.50003V11.25H12.2462L9.61928 8.62308L10.6731 7.53848L15.1346 12L10.6731 16.4615Z" fill="#1C1B1F"/>
</g>
</svg>
</template>
