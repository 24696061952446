import {DateTime, Duration} from 'luxon'
import {RepeatingOption} from '@/views/Events/Events.interface'
import {from} from 'linq-to-typescript'


type DateOnly = DateTime
type TimeOnly = Duration

interface EventDates {
  repeating: RepeatingOption
  startDate: DateOnly
  endDate: DateOnly
  startTime: Duration
  endTime: Duration
  dateChanges: DateChanges
}

interface DateChanges {
  added: DateOnly[],
  removed: DateOnly[]
}

interface MeetingOccurrence {
  start: DateTime;
  end: DateTime;
}

function iterateMeetingOccurrences(src: EventDates) {
  return iterateDates(src).select(date => createMeetingOccurrence(date, src.startTime, src.endTime))
}


function createMeetingOccurrence(date: DateOnly, startTime: TimeOnly, endTime: TimeOnly): MeetingOccurrence {
  if (startTime < endTime) {
    return {start: date.plus(startTime), end: date.plus(endTime)}
  } else {
    return {start: date.plus(startTime), end: date.plus(endTime).plus({day: 1})}
  }
}

function iterateDates(src: EventDates) {
  const dates = GetValidBaseDates(src)
  const {removed, added} = src.dateChanges
  return from(concatSorted(
    dates.except(removed,compareDates),
    added.sort(),
    (a, b) => a < b))
}

function compareDates(leftDate: DateOnly, rightDate:DateOnly){
  return leftDate.hasSame(rightDate,'day')
}

function* concatSorted<T>(as: Iterable<T>, bs: Iterable<T>, isLessThan: (a: T, b: T) => boolean) {
  const iteratorA = as[Symbol.iterator]()
  const iteratorB = bs[Symbol.iterator]()
  let a = iteratorA.next()
  let b = iteratorB.next()

  while (true) {
    if (!a.done && !b.done) {
      if (isLessThan(a.value, b.value)) {
        yield a.value
        a = iteratorA.next()
      } else {
        yield b.value
        b = iteratorB.next()
      }
    } else if (!a.done) {
      yield a.value
      a = iteratorA.next()
    } else if (!b.done) {
      yield b.value
      b = iteratorB.next()
    } else {
      break
    }
  }
}

function GetValidBaseDates(src: EventDates) {
  return GetBaseDates(src.startDate, src.repeating)
    .takeWhile(date => date <= src.endDate)
}


function GetBaseDates(start: DateOnly, repeating: RepeatingOption | null) {
  if (repeating == RepeatingOption.Custom) {
    return from([])
  }
  const nextDate = NextDate(repeating ?? RepeatingOption.DoesNotRepeat)
  return from(Unfold(start, nextDate))
}

function* Unfold<T>(value: T | null, nextValue: (item: T) => T | null): IterableIterator<T> {
  while (value !== null) {
    yield value
    value = nextValue(value)
  }
}

function NextDate(repeatingOption: RepeatingOption): (date: DateOnly) => (DateOnly | null) {
  if (repeatingOption == RepeatingOption.Biweekly)
    return today => today.plus({day: 14})
  if (repeatingOption == RepeatingOption.Weekly)
    return today => today.plus({day: 7})
  else
    return today => null
}

function isOverlapping(that: MeetingOccurrence, other: MeetingOccurrence) {
  return contains(that, other.start) || contains(that, other.end)
}

function contains(that: MeetingOccurrence, moment: DateTime) {
  return moment >= that.start && moment < that.end
}

export { MeetingOccurrence, EventDates, iterateMeetingOccurrences, iterateDates }