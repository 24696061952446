import { AddEventModel } from './../../views/Events/Events.interface'
import {inject} from 'vue'
import {BaseService} from '@/mid-layer/api-clients/BaseService'
import { IAddWebinarActions, IEventsStore } from './AddWebinar.interface'
import locales from './AddWebinar.locales.en.json'
import useHttpWrapper from '@/stores/HttpWrapper'
import { EventModel } from '@/views/Events/Events.interface'
import { ErrorPayload, ErrorType } from '@/mid-layer/api-clients/ApiExceptions'


export function createAddWebinarStore(): IEventsStore {

  const httpWrapper = useHttpWrapper(errorToMessage)

  const service = new BaseService<EventModel>('events')

  const actions: IAddWebinarActions = {
    addWebinar,
    editWebinar,
    deleteWebinar
  }

  function errorToMessage(error: ErrorPayload<any>) {
    switch (error.type) {
      case ErrorType.ConflictingEvent:
        return locales.ConflictingWebinarMessage
    }
  }

  async function addWebinar(val: EventModel) {
    const formData = new FormData()
    formData.append('event', JSON.stringify(val))
    formData.append('event_type', val.eventType)

    const eventService = new BaseService<FormData>('events')

    return await httpWrapper(
      locales.AddWebinarSuccess,
      locales.AddWebinarFailure,
      async () => await eventService.Create(formData)
    )
  }

  async function editWebinar(id: string, val: EventModel) {
    const headers = { 'If-Match': val.eTag ?? '' }

    const formData = new FormData()
    formData.append('event', JSON.stringify(val))

    const eventService = new BaseService<FormData>('events')

    return await httpWrapper(
      locales.UpdateWebinarSuccess,
      locales.UpdateWebinarFailure,
      async () => await eventService.Update(id, formData, headers)
    )
  }

  async function deleteWebinar(id: string) {
    return await httpWrapper(
      locales.DeleteWebinarSuccess,
      locales.DeleteWebinarFailure,
      async () => await service.Delete(id)
    )
  }

  return {
    state: null,
    actions
  }
}

const IAddWebinarStoreSymbol = Symbol('IAddWebinarStore')

export function injectAddWebinarStore() {
  return inject(IAddWebinarStoreSymbol, () => createAddWebinarStore(), true)
}
