const lsatColumns = [
    {
      key: 'startDate',
      label: 'Day/Date'
    },
    {
      key: 'time',
      label: 'Time'
    },
    {
      key: 'title',
      label: 'Title'
    },
    {
      key: 'tags',
      label: 'Tags'
    },
    {
      key: 'coachName',
      label: 'Instructor'
    },
    {
      key: 'document',
      label: ''
    },
    {
      key: 'recordingLink',
      label: ''
    },
    {
      key: 'isHidden',
      label: 'Hidden'
    }
  ]

  export {
    lsatColumns
}