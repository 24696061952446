
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    count: {
      type: Number,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean
    }
  }
})
