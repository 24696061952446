import APIBroker from '@/mid-layer/broker/api-broker'

export class BaseService<Entity> {
  private broker: APIBroker;
  private _resource: string;

  constructor(resource: string) {
    this.broker = APIBroker.Instance
    this._resource = resource
  }

  async Create(entity: Entity): Promise<string> {
    try {
      const response = await this.broker.Post<Entity, string>(
        this._resource,
        entity
      )
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async Update(
    id: string,
    entity: Entity,
    headers?: { [name: string]: string }
  ): Promise<void> {
    try {
      let response
      if (headers)
        response = await this.broker.Put<Entity, void>(
          this._resource,
          id,
          entity,
          undefined,
          headers
        )
      else
        response = await this.broker.Put<Entity, void>(
          this._resource,
          id,
          entity
        )

      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async Get(): Promise<Entity[]> {
    try {
      const response = await this.broker.Get<Entity[]>(this._resource)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async GetSingular(): Promise<Entity> {
    return await this.broker.Get<Entity>(this._resource)
  }

  async GetById(id: string): Promise<Entity> {
    try {
      const response = await this.broker.Get<Entity>(`${this._resource}/${id}`)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  
  async GetProfilePicture(id: string): Promise<Blob | undefined> {
    return await this.broker.Get<Blob>(`${this._resource}/${id}/profile-picture`, undefined, { responseType: 'blob'})
  }

  async GetEventDocument(eventId: string, documentName: string): Promise<Blob | undefined> {
    return await this.broker.Get<Blob>(
      `${this._resource}/${eventId}/documents/${documentName}`,
      undefined,
      { responseType: 'blob'}
    )
  }

  async Delete(id: string): Promise<void> {
    try {
      const response = await this.broker.Delete(this._resource, id)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
