
import { CourseTag } from '@/views/Events/Events.interface'
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import BsDialog from '@/components/BsDialog/BsDialog.vue'
import XCircleIcon from '@/components/icons/XCircleIcon.vue'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import TextEditor from '@/components/Editor/TextEditor.vue'
import useVuelidate, { ValidationArgs } from '@vuelidate/core'
import { required, maxLength, helpers, alpha } from '@vuelidate/validators'
import BsSelect from '@/components/BsSelect/BsSelect.vue'
import constants from '@/utils/Constants.json'
import { MultiSelectOptionsModel } from '../BsSelect/BsMultiSelect.interfaces'

export default defineComponent({
  components: {
    BsDialog,
    XCircleIcon,
    BsTextField,
    TextEditor,
    BsSelect
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    tagValue: {
      type: Object as PropType<CourseTag>,
      default: () => null
    },
    tagList: {
      type: Object as PropType<CourseTag[]>,
      default: () => null
    }
  },
  emits: ['update:isVisible', 'create', 'update', 'delete'],
  setup(props, {emit}) {

    const state = ref<CourseTag>(getInitialState())
    const existingTags = ref<CourseTag[]>()
    const editMode = ref<boolean>(!!props.tagValue)
    const colors = constants.Colors as MultiSelectOptionsModel[]

    const titleMustBeUnique = helpers.withMessage(
      'A Tag with that Title already exists', 
      (value: string) => !existingTags.value?.find(t => t.title === value)
    )

    const abbreviationMustBeUnique = helpers.withMessage(
      'A Tag with that Abbreviation already exists', 
      (value: string) => !existingTags.value?.find(t => t.abbreviation === value.toUpperCase())
    )

    const colorMustBeUnique = helpers.withMessage(
      'A Tag with that Color already exists', 
      (value: string) => !existingTags.value?.find(t => t.color === value)
    )

    const rules: ValidationArgs = {
      title: { required, titleMustBeUnique },
      abbreviation: { required, alpha, abbreviationMustBeUnique, maxLength: maxLength(3) },
      color: { required, colorMustBeUnique },
      description: { required, maxLength: maxLength(100) }
    }

    const v$ = useVuelidate(rules, state)

    watch(
      () => props.isVisible,
      () => {
        editMode.value = !!props.tagValue
        existingTags.value = JSON.parse(JSON.stringify(props?.tagList ?? {})) as CourseTag[]
        if (editMode.value) {
          state.value = JSON.parse(JSON.stringify(props?.tagValue ?? {})) as CourseTag
          existingTags.value = existingTags.value.filter(t => t.title != state.value.title)
        } else {
          state.value = getInitialState()
        }
      }
    )

    function getInitialState() {
      return {
        title: '',
        abbreviation: '',
        color: '',
        description: '',
        isActive: true
      }
    }

    function closeModal() {
      v$.value.$reset()
      state.value = getInitialState()
      emit('update:isVisible', false)
    }

    const computeVisibleModal = computed({
      get: () => props.isVisible,
      set: (val) => emit('update:isVisible', val)
    })

    async function onDelete() {
      emit('delete')
      closeModal()
    }

    async function onSubmit() {
      v$.value.$touch()

      if (v$.value.$invalid) {
        return
      }

      state.value.abbreviation = state.value.abbreviation.toUpperCase()

      editMode.value ? emit('update', state.value) : emit('create', state.value)

      closeModal()
    }

    return {
      closeModal,
      computeVisibleModal,
      state,
      editMode,
      onDelete,
      onSubmit,
      colors,
      existingTags,
      v$
    }
  }
})
