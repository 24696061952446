
import { defineComponent, PropType } from 'vue'
import ExitToAppIcon from '@/components/icons/IconExitToApp.vue'
import { EventModel } from '../Events/Events.interface'
import { Coach } from '@/types/Coach'
import { getDateFromUTC } from './Events.utils'

export default defineComponent({
  props: {
    events: {
      type: Array as PropType<EventModel[]>,
      required: true
    },
    coaches: {
      type: Array as PropType<Coach[]>,
      required: true
    }
  },
  components: {
    ExitToAppIcon
  },
  setup(props) {
    const exportEvents = () => {
      const csvRows = props.events.map(evnt => {
        const coachName = props.coaches.find(coach => coach.id == evnt.coachId)?.name
        const startDate = getDateFromUTC(evnt.startDate, evnt.startTime, evnt.timeZone)
        const endDate = getDateFromUTC(evnt.endDate, evnt.endTime, evnt.timeZone)

        const dayOfWeek = startDate.toLocaleString({ weekday: 'long' })
        const dates =
          startDate.toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })
          + ' - '
          + endDate.toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })
        const time =
          startDate.toLocaleString({ hour: 'numeric', minute: '2-digit' })
          + ' - '
          + endDate.toLocaleString({ hour: 'numeric', minute: '2-digit' }) 
          + ' '
          + evnt.timeZone.toUpperCase()

        const eventCsvRow = [
            coachName,
            dayOfWeek,
            '"' + dates + '"',
            time,
            evnt.attendees.length + '/100'
          ]
        const eventCsvRows = evnt.attendees.map(attendee => [...eventCsvRow, attendee])
        return eventCsvRows.length ? eventCsvRows.join('\n') : eventCsvRow
      })

      const headerRow = [
        'Coach Name',
        'Day of Week',
        'Dates',
        'Time',
        'Total Users',
        'User'
      ]
      const csv = [headerRow, ...csvRows].join('\n')

      const filename = 'MBC Events.csv'
      const file: Blob = new File([csv], filename, { type: 'text/csv;charset=utf-8' })
      const url = URL.createObjectURL(file)
      const anchor = document.createElement('a')
      anchor.href = url
      anchor.download = filename
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
      URL.revokeObjectURL(url)
    }

    return {
     exportEvents 
    }
  }
})

