import { createRouter, createWebHistory } from 'vue-router'
import UnAuthorizedView from '@/views/UnAuthorizedView.vue'
import ForbiddenView from '@/views/ForbiddenView.vue'
import PageNotFoundView from '@/views/PageNotFoundView.vue'
import { oktaAuth } from '@/mid-layer/auth/okta-auth'
import LoginCallbackView from '@/views/LoginCallbackView.vue'
import HomeView from '@/views/HomeView.vue'
import OpsAdminView from '@/views/OpsAdminView/OpsAdminView.vue'
import { useUserStore } from '@/stores/UserStore'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin',
      name: 'admin',
      component: OpsAdminView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/callback',
      name: 'login-callback',
      component: LoginCallbackView,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: UnAuthorizedView,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/forbidden',
      name: 'forbidden',
      component: ForbiddenView,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: PageNotFoundView,
      meta: {
        requiresAuth: false
      }
    }
  ]
})

router.beforeEach(async (to) => {
  if (to.meta.requiresAuth && !(await oktaAuth.isAuthenticated())) {
    oktaAuth.signInWithRedirect()
    return false
  } else if (
    to.meta.requiresAuth &&
    (await oktaAuth.isAuthenticated()) &&
    !useUserStore().isAuthenticated
  ) {
    await useUserStore().login(await oktaAuth.getUser())
  }
  return true
})

export default router
