
import { defineComponent, inject, ref, computed } from 'vue'
import {
  IBsTabContainerStoreSymbol,
  IBsTabContainerStore
} from '@/components/BsTab/BsTab.interfaces'

export const BsTabList = defineComponent({
  name: 'BsTabList',
  setup(props, { slots }) {
    const store = inject<IBsTabContainerStore>(IBsTabContainerStoreSymbol)
    const tablist = ref<HTMLElement>()
    const existLeft = computed<boolean>(() =>
      Boolean(slots.left && slots.left())
    )
    const existRight = computed<boolean>(() =>
      Boolean(slots.right && slots.right())
    )

    function handleTabListFocusIn() {
      store?.actions.activate()
    }

    function handleTabListFocusOut() {
      setTimeout(() => {
        if (!tablist.value?.contains(document.activeElement)) {
          store?.actions.deactivate()
          store?.actions.focusReset()
        }
      })
    }

    return {
      tablist,
      existLeft,
      existRight,
      handleTabListFocusIn,
      handleTabListFocusOut
    }
  }
})

export default BsTabList
export type BsTabListInstance = InstanceType<typeof BsTabList>;
