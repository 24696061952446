import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e79fc98"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "aria-labelledby", "hidden"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.baseId + '__tabpanel',
    class: "BsTabPanel",
    role: "tabpanel",
    tabindex: "0",
    "aria-labelledby": _ctx.baseId + '__tab',
    hidden: !_ctx.selected
  }, [
    _renderSlot(_ctx.$slots, "default", { selected: _ctx.selected }, undefined, true)
  ], 8, _hoisted_1))
}