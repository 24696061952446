export interface UserDetailsModel {
    fullName: string;
    nameKey: string;
    isAuthenticated: boolean;
}

export interface DropdownOptionModel {
    label: string;
    icon: string;
    key: string;
    loading?: boolean
}

export enum BarbriProduct {
  BarReview = 'Bar Review',
  PowerScore = 'PowerScore'
}