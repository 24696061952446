import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7990fbc0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id", "value", "checked", "aria-describedby", "disabled"]
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "BsCheckbox__right" }
const _hoisted_5 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      id: _ctx.id,
      class: _normalizeClass(["BsCheckbox form-check", { 'bs-invalid-field': _ctx.dirty && _ctx.invalid }])
    }, [
      _createElementVNode("input", {
        id: _ctx.id + '__input',
        class: _normalizeClass(["form-check-input", {
          'is-invalid': _ctx.dirty && _ctx.invalid,
        }]),
        type: "checkbox",
        value: _ctx.modelValue,
        checked: _ctx.modelValue,
        "aria-describedby": _ctx.dirty ? _ctx.invalidMessageId : null,
        disabled: _ctx.disabled,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
      }, null, 42, _hoisted_2),
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            class: "form-check-label bs-field-label ms-3",
            for: _ctx.id + '__input'
          }, _toDisplayString(_ctx.label), 9, _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "right", {}, undefined, true)
      ]),
      (_ctx.dirty)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            id: _ctx.invalidMessageId,
            class: "invalid-feedback"
          }, _toDisplayString(_ctx.invalidMessage), 9, _hoisted_5))
        : _createCommentVNode("", true)
    ], 10, _hoisted_1)
  ]))
}