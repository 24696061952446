<script>
import {useLoaderStore} from '@/stores/LoaderStore'
import {storeToRefs} from 'pinia'
import {defineComponent} from 'vue'

export default defineComponent({
  setup() {
    const loaderStore = useLoaderStore()
    const {getIsVisible} = storeToRefs(loaderStore)
    return {getIsVisible}
  }
})

</script>

<template>
  <div class="semiTransparenDiv" v-if="getIsVisible"></div>
</template>

<style lang="scss" scoped>

.semiTransparenDiv {
  width: 100%;

  /*-Lets Center the Spinner-*/
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  /*Centering my shade */
  margin-bottom: 40px;
  margin-top: 60px;

  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.semiTransparenDiv::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 80px;
  height: 80px;
  border-style: solid;
  border: 5px solid black;
  border-top-color: #6CC4EE;
  border-width: 7px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;

  /* Lets make it go round */
  animation: spin .8s linear infinite;
}

</style>
