import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4797e28a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-content m-3" }
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "scroll-content" }
const _hoisted_4 = { class: "m-0" }
const _hoisted_5 = { class: "footer-content" }
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsDialog = _resolveComponent("BsDialog")!

  return (_openBlock(), _createBlock(_component_BsDialog, {
    visible: _ctx.isModalVisible,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isModalVisible) = $event)),
    ariaLabelledBy: `delete-${_ctx.itemType.toLowerCase()}-heading`,
    class: "delete-confirmation-modal"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", {
          id: `delete-${_ctx.itemType.toLowerCase()}-heading`,
          class: "heading"
        }, " Delete " + _toDisplayString(_ctx.itemType.charAt(0).toUpperCase() + _ctx.itemType.substring(1).toLowerCase()), 9, _hoisted_2)
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          class: "btn btn-text-primary",
          onClick: _cache[0] || (_cache[0] = () => _ctx.emit('close'))
        }, " Cancel "),
        _createElementVNode("button", {
          class: "btn btn-primary",
          disabled: _ctx.disableDeleteButton,
          onClick: _cache[1] || (_cache[1] = () => _ctx.emit('delete'))
        }, " Delete ", 8, _hoisted_6)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, "Are you sure you want to delete this " + _toDisplayString(_ctx.itemType.toLowerCase()) + "?", 1)
      ])
    ]),
    _: 1
  }, 8, ["visible", "ariaLabelledBy"]))
}