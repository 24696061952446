
import { defineComponent, PropType, computed, unref, ref } from 'vue'
import MultiSelect from 'primevue/multiselect'
import type { Validation } from '@vuelidate/core'
import { createAutoIncrementIdProp } from '@/utils/VueTools'
import type {
  MultiSelectOptionsModel,
  MultiSelectEventModel
} from './BsMultiSelect.interfaces'

const BsMultiSelect = defineComponent({
  name: 'BsMultiSelect',
  components: {
    MultiSelect
  },
  props: {
    id: createAutoIncrementIdProp('BsMultiSelect'),
    modelValue: {
      type: Array as PropType<MultiSelectOptionsModel[] | string[]>,
      default: () => []
    },
    label: {
      type: String,
      default: () => ''
    },
    disabled: Boolean,
    validation: {
      type: Object as PropType<Validation>,
      default: () => undefined
    },
    options: {
      type: Array as PropType<MultiSelectOptionsModel[]>,
      required: true
    },
    display: {
      type: String as PropType<'chip' | 'comma'>,
      default: () => 'chip'
    },
    placeholder: {
      type: String,
      default: () => 'Select...'
    },
    optionLabel: {
      type: String,
      default: () => 'text'
    },
    optionValue: {
      type: String,
      default: () => 'value'
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const value = computed(() => props.modelValue)
    const selectId = computed(() => props.id + '__multiselect')
    const invalidMessageId = computed(() => props.id + '__invalid_message')
    const dirty = computed<boolean>(() => {
      return props.validation?.$dirty ?? false
    })

    const invalid = computed<boolean>(() => {
      return props.validation?.$invalid ?? false
    })

    const invalidMessage = computed<string>(() => {
      const message = props.validation?.$errors?.[0]?.$message ?? ''
      return unref(message)
    })

    const showRequiredAttr = computed<boolean>(() => {
      return props.validation
        ? Object.keys(props.validation).includes('required')
        : false
    })

    function handleChange(event: MultiSelectEventModel) {
      emit('update:modelValue', event.value)
    }

    function formatOptionLabel(item: MultiSelectOptionsModel | string): string {
      if (typeof item === 'string') {
        return item
      }
      return item.text ?? item.label ?? ''
    }

    return {
      value,
      selectId,
      invalidMessageId,
      dirty,
      invalid,
      invalidMessage,
      showRequiredAttr,
      handleChange,
      formatOptionLabel
    }
  }
})

export default BsMultiSelect
