import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65cd04cf"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["for", "data-invalid", "data-disabled"]
const _hoisted_3 = { class: "input-group" }
const _hoisted_4 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: _normalizeClass(["BsMultiSelect form-control-helper", { 'bs-invalid-field': _ctx.dirty && _ctx.invalid }])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.selectId,
          class: "BsMultiSelect__label form-label d-flex bs-field-label",
          "data-invalid": _ctx.dirty && _ctx.invalid,
          "data-disabled": _ctx.disabled
        }, _toDisplayString(_ctx.label), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_MultiSelect, {
        "input-id": _ctx.selectId,
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        "append-to": "self",
        "option-label": _ctx.formatOptionLabel,
        options: _ctx.options,
        "option-value": _ctx.optionValue,
        placeholder: _ctx.placeholder,
        display: _ctx.display,
        disabled: _ctx.disabled,
        "aria-describedby": _ctx.dirty ? _ctx.invalidMessageId : null,
        required: _ctx.dirty && _ctx.invalid,
        "show-toggle-all": false,
        class: _normalizeClass({
          'is-invalid': _ctx.dirty && _ctx.invalid,
          BsMultiSelect__select: true,
        }),
        onChange: _ctx.handleChange
      }, null, 8, ["input-id", "modelValue", "option-label", "options", "option-value", "placeholder", "display", "disabled", "aria-describedby", "required", "class", "onChange"]),
      (_ctx.dirty)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            id: _ctx.invalidMessageId,
            class: "invalid-feedback"
          }, _toDisplayString(_ctx.invalidMessage), 9, _hoisted_4))
        : _createCommentVNode("", true)
    ])
  ], 10, _hoisted_1))
}