export const AddCoach = 'ADD_COACH'
export const EditCoach = 'EDIT_COACH'
export const OpenModalAddCoach = 'OPEN_MODAL_ADD_COACH'
export const OpenModalEditCoach = 'OPEN_MODAL_EDIT_COACH'
export const SubmitModal = 'SUBMIT_MODAL'
export const OpenSpinner = 'OPEN_SPINNER'
export const CloseSpinner = 'CLOSE_SPINNER'
export const CoachListUpdated = 'COACH_LIST_UPDATED'
export const EventListUpdated = 'EVENT_LIST_UPDATED'
export const WebinarListUpdated = 'COACH_LIST_UPDATED'
export const ApplyEventSearchCriteria = 'APPLY_EVENT_SEARCH_CRITERIA'
export const OpenModalCourseSettings = 'OPEN_MODAL_EDIT_COURSE_SETTINGS'
export const CourseSettingsUpdated = 'COURSE_SETTINGS_UPDATED'
