
import { PropType, defineComponent } from 'vue'
import { UserDetailsModel } from '@/components/Header/Header.interface'

const UserNameIcon = defineComponent({
  name: 'UserNameIcon',
  props: {
    nameDetails: {
      type: Object as PropType<UserDetailsModel>
    },
    isDropdownOpen: {
      type: Boolean,
      default: () => null
    }
  },
  emits: ['toggle-dropdown'],
  setup(props, { emit }) {
    function handleDropdownOpen() {
      emit('toggle-dropdown', true)
    }
    return { handleDropdownOpen }
  }
})

export default UserNameIcon
