import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f191f31e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "username-icon" }
const _hoisted_2 = ["aria-expanded", "role"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      id: "username-icon",
      "aria-label": "username icon",
      "aria-expanded": _ctx.isDropdownOpen,
      role: _ctx.isDropdownOpen !== null ? 'combobox' : 'image',
      class: _normalizeClass(_ctx.isDropdownOpen !== null ? 'c-pointer' : ''),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleDropdownOpen && _ctx.handleDropdownOpen(...args)))
    }, _toDisplayString(_ctx.nameDetails.nameKey), 11, _hoisted_2)
  ]))
}