
import { defineComponent, PropType, computed, unref } from 'vue'
import type { Validation } from '@vuelidate/core'
import { createAutoIncrementId } from '@/utils/VueTools'

const BsSelect = defineComponent({
    name: 'BsSelect',
    props: {
        id: {
            type: String,
            default: createAutoIncrementId('BsSelect')
        },
        modelValue: {
            type: [String, Boolean, Number, Object],
            default: () => ''
        },
        label: {
            type: String,
            default: () => ''
        },
        disabled: Boolean,
        validation: {
            type: Object as PropType<Validation>,
            default: () => undefined
        },
        prepend: {
            type: String,
            default: () => ''
        },
        append: {
            type: String,
            default: () => ''
        },
        options: {
            type: Array as PropType<{ text: string; value: unknown }[]>,
            default: () => []
        }
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const selectId = computed(() => props.id + '__select')
        const invalidMessageId = computed(() => props.id + '__invalid_message')

        const dirty = computed<boolean>(() => {
            return props.validation?.$dirty ?? false
        })

        const invalid = computed<boolean>(() => {
            return props.validation?.$invalid ?? false
        })

        const invalidMessage = computed<string>(() => {
            const message = props.validation?.$errors?.[0]?.$message ?? ''
            return unref(message)
        })

        const showRequiredAttr = computed<boolean>(() => {
            return props.validation
                ? Object.keys(props.validation).includes('required')
                : false
        })

        function handleChange(event: Event) {
            const target = event.target as HTMLInputElement
            let value: boolean | string = target.value

            // Accounts for boolean values
            if (value == 'true') {
                value = true
            } else if (value == 'false') {
                value = false
            }

            emit('update:modelValue', value)
        }

        return {
            selectId,
            invalidMessageId,
            dirty,
            invalid,
            invalidMessage,
            showRequiredAttr,
            handleChange
        }
    }  
})

export default BsSelect
