import {
  ErrorAdditions,
  ErrorPayload,
  ValidationError,
  ErrorType
} from '@/mid-layer/api-clients/ApiExceptions'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { useLoaderStore } from '@/stores/LoaderStore'
import locales from './HttpWrapper.locale.en.json'
import { AxiosResponse } from 'axios'

type ErrorToMessage = (error: ErrorPayload<any>) => string | undefined;

function getDefaultErrorMessage(ex: ErrorAdditions, exception: ErrorPayload<any>) {
  switch (exception.type) {
    case ErrorType.ValidationError: {
      const errors = exception.data as Array<ValidationError>
      return errors.map((value) => value.errorMessage).join(', ')
    }
    case ErrorType.AddedAttendeeToExpiredEvent:
      return locales.expired_event_enrollment
    case ErrorType.UniqueViolation:
      return locales.duplicate_record
    case ErrorType.SalesforceError:
      return locales.event_salesforce_failure
    default:
      switch (ex.status) {
        case 404:
          return locales.requested_resource_not_found
          break
        case 409:
          return locales.data_sync_error
          break
        case 500:
          return locales.internal_server_error
          break
      }
  }
}

export default function useHttpWrapper(
  errorToMessage: ErrorToMessage | undefined = undefined
) {
  const { showSuccessToast, showErrorToast } = useToast()
  const { setIsVisible: setLoaderIsVisible } = useLoaderStore()

  const errorToMessage2 = errorToMessage || (() => undefined)

  function showError(message: string | undefined) {
    if (message) {
      showErrorToast({
        message: message,
        position: 'top-center'
      })
    }
    else {
      showErrorToast({
        message: 'An unknown error has occurred.',
        position: 'top-center'
      })
    }
  }

  function getSuccessMessage(successMessage: string, response: any) {
    switch (response.type)
    {
      case ErrorType.SalesforceError: {
        return locales.event_salesforce_partial_success
      }
      default:
        return successMessage
    }
  }

  function getErrorMessage(errorMessage: string, ex: ErrorAdditions | AxiosResponse<string>) {
    if (ex.data) {
      if(typeof ex.data === 'string')
      {
        return ex.data
      }
      else {
        const exception = ex.data as ErrorPayload<any>
        return (
          errorToMessage2(exception) ||
          getDefaultErrorMessage(<ErrorAdditions>ex, exception) ||
          errorMessage
        )
      }
    }
  }

  return async function httpWrapper<T>(
    successMessage: string,
    errorMessage: string,
    computation: () => Promise<T>
  ) {
    setLoaderIsVisible(true)
    try {
      const response = await computation()
      showSuccessToast({ message: getSuccessMessage(successMessage, response), position: 'top-center' })
      return response
    } catch (ex) {
      showError(getErrorMessage(errorMessage, ex as ErrorAdditions))
      throw ex
    } finally {
      setLoaderIsVisible(false)
    }
  }
}
