import {inject} from 'vue'
import {BaseService} from '@/mid-layer/api-clients/BaseService'
import {IAddCoachActions, IEventsStore} from './AddCoach.interface'
import locales from './AddCoach.locales.en.json'
import {Coach} from '@/types/Coach'
import useHttpWrapper from '@/stores/HttpWrapper'


export function createAddCoachStore(): IEventsStore {

  const httpWrapper = useHttpWrapper()

  const service = new BaseService<FormData>('coaches')

  const actions: IAddCoachActions = {
    addCoach,
    editCoach,
    deleteCoach
  }

  async function addCoach(val: Coach, profilePicture: File | undefined) {
    const formData = new FormData()
    formData.append('coach', JSON.stringify(val))
    if (profilePicture) {
      formData.append('profilePicture', profilePicture)
    }
    return await httpWrapper(
      locales.add_coach_success,
      locales.add_coach_failure,
      async () => await service.Create(formData)
    )
  }

  async function editCoach(id: string, val: Coach, profilePicture: File | undefined) {
    const formData = new FormData()
    formData.append('coach', JSON.stringify(val))
    if (profilePicture) {
      formData.append('profilePicture', profilePicture)
    }
    return await httpWrapper(
      locales.update_coach_success,
      locales.update_coach_failure,
      async () => await service.Update(id, formData)
    )
  }

  async function deleteCoach(id: string) {
    return await httpWrapper(
      locales.delete_coach_success,
      locales.delete_coach_failure,
      async () => await service.Delete(id)
    )
  }

  return {
    state: null,
    actions
  }
}

const IAddCoachStoreSymbol = Symbol('IAddCoachStore')

export function injectAddCoachStore() {
  return inject(IAddCoachStoreSymbol, () => createAddCoachStore(), true)
}
