import {defineStore} from 'pinia'
import {computed, ref} from 'vue'

export const useLoaderStore = defineStore('loader', () => {
    const isVisible = ref(false)

    function setIsVisible(value: boolean) {
        isVisible.value = value
    }

    const getIsVisible = computed((value) => isVisible.value)

    return {getIsVisible, setIsVisible}
})